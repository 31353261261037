import React, {  } from "react";
import { useRef } from "react";
import "../index.css";
import ImageCard from "../components/ImageCard";
import PakageCard from "../components/PakageCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import Testimonial from "../components/Testimonial";
import TestimonialSkeleton from "../components/TestimonialSkeleton";
import CategoryCard from "../components/CategoryCard";
import { Oval } from "react-loader-spinner";
import { useQuery } from "@tanstack/react-query";
import InstagramEmbed from "../components/InstagramEmbed";
import Slider from "../components/Slider";
function Home() {

  // const [pakages, setPakages] = useState([]);
  const { data, isSuccess } = useQuery({
    queryKey: ["home"],
    queryFn: () => {
      return fetch(process.env.REACT_APP_BASE_URL + "get_home")
        .then((res) => res.json())
        .then((res) => res);
    },
    staleTime: 900000,
    refetchInterval: 900000,
    refetchIntervalInBackground: true,
  });

  // var pakages = [
  //   {
  //     image:
  //       "https://trektales.pythonanywhere.com/DataBase/Escape/Tumbnail/kedarnath.jpeg",
  //     city: "Nagpur",
  //     state: "Maharstra",
  //     duration: "5 days 6 Nights",
  //     desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime soluta voluptate sit officia placeat ipsa est, hic animi harum labore temporibus odit quas voluptates! Modi tempore, esse eveniet dignissimos minus quia animi voluptatibus, perspiciatis, nulla quibusdam eum fuga maiores obcaecati ratione atque! Asperiores veniam sequi voluptates tempore, debitis assumenda eaque repellendus reiciendis aperiam dolore molestiae itaque cumque ipsa eveniet explicabo. Cupiditate placeat eos velit laboriosam nostrum. Deleniti illum amet totam odit provident? Animi voluptatibus debitis praesentium autem perferendis vitae possimus nemo quod voluptatum sapiente consectetur cum minus excepturi incidunt, repellat sequi sit architecto maxime qui beatae. Labore adipisci enim fugiat.",
  //     original_price: "RS. 15,000",
  //     new_price: "RS.16,000",
  //   },
  //   {
  //     image:
  //       "https://trektales.pythonanywhere.com/DataBase/Escape/Tumbnail/kedarnath.jpeg",
  //     city: "Nagpur",
  //     state: "Maharstra",
  //     duration: "5 days 6 Nights",
  //     desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime soluta voluptate sit officia placeat ipsa est, hic animi harum labore temporibus odit quas voluptates! Modi tempore, esse eveniet dignissimos minus quia animi voluptatibus, perspiciatis, nulla quibusdam eum fuga maiores obcaecati ratione atque! Asperiores veniam sequi voluptates tempore, debitis assumenda eaque repellendus reiciendis aperiam dolore molestiae itaque cumque ipsa eveniet explicabo. Cupiditate placeat eos velit laboriosam nostrum. Deleniti illum amet totam odit provident? Animi voluptatibus debitis praesentium autem perferendis vitae possimus nemo quod voluptatum sapiente consectetur cum minus excepturi incidunt, repellat sequi sit architecto maxime qui beatae. Labore adipisci enim fugiat.",
  //     original_price: "RS. 15,000",
  //     new_price: "RS.16,000",
  //   },
  //   {
  //     image:
  //       "https://trektales.pythonanywhere.com/DataBase/Escape/Tumbnail/kedarnath.jpeg",
  //     city: "Nagpur",
  //     state: "Maharstra",
  //     duration: "5 days 6 Nights",
  //     desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime soluta voluptate sit officia placeat ipsa est, hic animi harum labore temporibus odit quas voluptates! Modi tempore, esse eveniet dignissimos minus quia animi voluptatibus, perspiciatis, nulla quibusdam eum fuga maiores obcaecati ratione atque! Asperiores veniam sequi voluptates tempore, debitis assumenda eaque repellendus reiciendis aperiam dolore molestiae itaque cumque ipsa eveniet explicabo. Cupiditate placeat eos velit laboriosam nostrum. Deleniti illum amet totam odit provident? Animi voluptatibus debitis praesentium autem perferendis vitae possimus nemo quod voluptatum sapiente consectetur cum minus excepturi incidunt, repellat sequi sit architecto maxime qui beatae. Labore adipisci enim fugiat.",
  //     original_price: "RS. 15,000",
  //     new_price: "RS.16,000",
  //   },
  //   {
  //     image:
  //       "https://trektales.pythonanywhere.com/DataBase/Escape/Tumbnail/kedarnath.jpeg",
  //     city: "Nagpur",
  //     state: "Maharstra",
  //     duration: "5 days 6 Nights",
  //     desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime soluta voluptate sit officia placeat ipsa est, hic animi harum labore temporibus odit quas voluptates! Modi tempore, esse eveniet dignissimos minus quia animi voluptatibus, perspiciatis, nulla quibusdam eum fuga maiores obcaecati ratione atque! Asperiores veniam sequi voluptates tempore, debitis assumenda eaque repellendus reiciendis aperiam dolore molestiae itaque cumque ipsa eveniet explicabo. Cupiditate placeat eos velit laboriosam nostrum. Deleniti illum amet totam odit provident? Animi voluptatibus debitis praesentium autem perferendis vitae possimus nemo quod voluptatum sapiente consectetur cum minus excepturi incidunt, repellat sequi sit architecto maxime qui beatae. Labore adipisci enim fugiat.",
  //     original_price: "RS. 15,000",
  //     new_price: "RS.16,000",
  //   },
  // ];



  // let change_text = [
  //   "Experiences",
  //   "travels",
  //   "Hotels",
  //   "Adventures",
  //   "Experiences",
  // ];

  const HeroSection = useRef();
  const mainHeroSection = useRef(null);

  return (
    <>
      <section
        className="h-auto w-full z-0 flex justify-center"
        ref={mainHeroSection}
        id="mainHeroSection"
      >
        <section
          className="md:h-screen  min-h-screen   w-[100%] transition-all ease-out flex  justify-center  items-start   z-10  overflow-hidden"
          ref={HeroSection}
          id="HeroSection"
        >
            <Slider isSuccess={isSuccess} data={data} />
        </section>
      </section>
      {/* <section className="mb-10 background-radial-gradient overflow-hidden  w-full min-h-screen   flex justify-center items-center  select-none  z-auto">
        <div className="px-6 py-12 lg:py-24 md:px-12  text-center lg:text-left w-screen">
          <div className="container mx-auto xl:px-32 text-gray-800 ">
            <div className=" ">
              <div className="mt-12 lg:mt-0 z-10">
                <h1 className="text-4xl md:text-5xl xl:text-6xl font-bold tracking-tight mb-12 text-stone-300 capitalize text-center">
                  We Take Care of Your
                  <br />
                  <div id="change_text_main_window">
                    <div id="change_text">
                      <ol
                        id="change_text_ol"
                        className="text-[--primary-color]"
                      >
                        {change_text.map((el) => {
                          return (
                            <li className="list-none  animate_heading">{el}</li>
                          );
                        })}
                      </ol>
                    </div>
                  </div>
                </h1>
              </div>
              <div className="mb-12 lg:mb-0 relative">
                <div
                  id="radius-shape-1"
                  className="absolute rounded-full shadow-lg"
                ></div>
                <div id="radius-shape-2" className="absolute shadow-lg"></div>
                <div className="block rounded-lg shadow-lg bg-glass px-6 py-12 md:px-12">
                  <form
                    className="flex flex-col gap-3"
                    onSubmit={handleQuerySubmit}
                  >
                    <div className="flex flex-row   w-full rounded px-3 py-1.5 text-base font-normal  bg-white bg-clip-padding">
                      <input
                        type="text"
                        style={{ outline: "none" }}
                        className="form-control block w-full .noborder  px-3 py-1.5 text-base font-normal border-none outline-none transition ease-in-out  "
                        placeholder="Search Destinations,Tours,Activites"
                        name="query"
                        id="Search"
                        required
                      />
                      <button type="submit" className="px-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                      </button>
                    </div>
                    <div className="text-start  block">
                      <div className="text-[var(--text-2)] capitalize text-start text-sm mb-2">
                        top Searches :
                      </div>
                      <div className="">
                        {isSuccess
                          ? data?.search.map((e, index) => {
                              return (
                                <span
                                  className="Popular_search capitalize"
                                  key={index}
                                >
                                  <Link to={"/search/" + e.search}>
                                    {e.search}
                                  </Link>
                                </span>
                              );
                            })
                          : [1, 2, 3, 4, 5].map((e, index) => {
                              return (
                                <span
                                  className="Popular_search capitalize animate-pulse transition-all duration-1000  "
                                  key={index}
                                >
                                  <span className="px-9 h-2 "></span>
                                </span>
                              );
                            })}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="">
        <div className="max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8 mx-auto">
          <div className="flex flex-col lg:flex-row lg:items-center lg:gap-x-16  ">
            <div className="mx-auto max-w-lg text-center lg:mx-0 lg:text-left lg:w-1/3 w-9/12 my-2 lg:my-0">
              <h2 className="text-3xl font-semibold  w-auto  mx-auto">
                {" "}
                Find The Perfect Escape
              </h2>
              <div className="border border-[var(--primary-color)] lg:w-1/4 border-b-2 mt-3 mb-2 w-1/2 mx-auto lg:ml-0 "></div>
              <h3 className="text-base">Discover your ideal Experience</h3>
            </div>

            <div className="flex lg:flex-nowrap flex-wrap  gap-4  w-full justify-center ">
              {isSuccess
                ? data?.best_locations.map((el, index) => {
                    return (
                      <ImageCard
                        index={index}
                        link={el.city.name}
                        image={process.env.REACT_APP_MEDIA_URL + el.thubnail}
                        name={el.city.name}
                        desc={el.description}
                      />
                    );
                  })
                : [1, 2, 3].map((el, index) => {
                    return <ImageCard loading={true} />;
                  })}
            </div>
          </div>
        </div>
      </section>
      <section className="max-w-screen md:h-screen my-auto py-16">
        <div className="mx-auto my-auto ">
          <div className="container mx-auto my-auto ">
            <h1 className="text-4xl font-bold title-font text-gray-900 mb-12 text-center  tracking-tight md:text-6xl capitalize  text-clip opacity-80 ">
              Trending packages
            </h1>
          </div>
        </div>
        {isSuccess ? (
          <div className="w-max-screen overflow-hidden">
            <Swiper
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swip) => console.log(swip)}
              modules={[Navigation]}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 50,
                },
              }}
            >
              {data.packages.map((e, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <PakageCard
                        key={index}
                        image={e.tumbnail[0].photo}
                        city={e.main_location.city.name}
                        state={e.main_location.city.region.name}
                        duration={`${e.duration.day} days ${e.duration.night} Nights`}
                        heading={e.heading}
                        desc={e.packageInfo}
                        price={e.price}
                        slug={e.slug}
                        id={e.id}
                        loop={true}
                      />
                    </SwiperSlide>
                  </>
                );
              })}
              {/* {data.map((e, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <PakageCard
                        key={index}
                        image={e.image}
                        city={e.city}
                        state={e.state}
                        duration={e.duration}
                        desc={e.desc}
                        original_price={e.original_price}
                        new_price={e.new_price}
                      />
                    </SwiperSlide>
                  </>
                );
              })} */}
            </Swiper>
          </div>
        ) : (
          <div className="w-full text-center flex justify-center ">
            <Oval
              visible={true}
              height="64"
              width="64"
              color="var(--text)"
              secondaryColor="var(--primary-color2)"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
      </section>
      {/* categories */}
      <section className="max-w-screen py-16">
        <div className="mx-auto ">
          <div className="container mx-auto my-auto ">
            <h1 className="text-4xl font-bold title-font text-gray-900 mb-12 text-center  tracking-tight md:text-6xl capitalize  text-clip opacity-80 ">
              explore categories
            </h1>
          </div>
        </div>
        <div className="w-max-screen overflow-hidden flex flex-row  flex-wrap justify-center gap-10 items-baseline">
          {isSuccess
            ? data?.categorys.map((el, index) => {
                return <CategoryCard key={index} data={el} />;
              })
            : [1, 2, 3, 4, 5, 6].map((el, index) => {
                return <CategoryCard key={index} data={el} loading={true} />;
              })}
        </div>
      </section>
      {/* reviews */}
      <section className="text-gray-600   body-font">
        <div className="px-5 py-24 mx-auto my-auto">
          <h1 className="text-4xl font-bold title-font text-gray-900 mb-12 text-center  tracking-tight md:text-6xl capitalize  text-clip opacity-80 ">
            Testimonials
          </h1>
          <div className="flex flex-wrap -m-4 my-8">
            <Swiper
              spaceBetween={30}
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
            >
              {isSuccess
                ? data?.testimonials.map((e, index) => {
                    return (
                      <SwiperSlide>
                        <Testimonial key={index} data={e} />
                      </SwiperSlide>
                    );
                  })
                : [0, 1, 2, 3, 4, 5, 6].map((e, index) => {
                    return (
                      <SwiperSlide>
                        <TestimonialSkeleton key={index} data={e} />
                      </SwiperSlide>
                    );
                  })}
            </Swiper>
          </div>
          <div className="flex flex-wrap -m-4 my-8">
            <Swiper
              spaceBetween={30}
              loop={true}
              centeredSlides={false}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                reverseDirection: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
            >
              {isSuccess
                ? data?.testimonials2.map((e, index) => {
                    return (
                      <SwiperSlide>
                        <Testimonial key={index} data={e} />
                      </SwiperSlide>
                    );
                  })
                : [0, 1, 2, 3, 4, 5, 6].map((e, index) => {
                    return (
                      <SwiperSlide>
                        <TestimonialSkeleton key={index} data={e} />
                      </SwiperSlide>
                    );
                  })}
            </Swiper>
          </div>
        </div>
      </section>
      {/* Instagram Embed  */}
      <InstagramEmbed />
    </>
  );
}

export default Home;
