import React from "react";
import HeadText from "../components/HeadText";
import InstagramEmbed from "../components/InstagramEmbed";
import heroImage from "../assects/aboutHero.jpg";
import Testimonial from "../components/Testimonial";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import TestimonialSkeleton from "../components/TestimonialSkeleton";
import { useQuery } from "@tanstack/react-query";

function AboutUs() {
  const { data, isSuccess } = useQuery({
    queryKey: ["home"],
    queryFn: () => {
      return fetch(process.env.REACT_APP_BASE_URL + "get_home")
        .then((res) => res.json())
        .then((res) => res);
    },
    staleTime: 900000,
    refetchInterval: 900000,
    refetchIntervalInBackground: true,
  });
  
  return (
    <div className="bg-gray-50 py-44">
      <HeadText title="About Us" subtitle="OUR STORY" />
      <div className="sm:mx-10 mx-4 -py-20">
        <div className="">
          <hr className="border mb-5" />
          <p className="text-center w-[75%] mx-auto opacity-95">
            Welcome to Aarohan Holidays, your gateway to unforgettable journeys
            and extraordinary adventures. At Aarohan Holidays, we believe travel
            is more than just visiting new places—it's about creating memories
            that last a lifetime. With our premier travel agency, you can embark
            on journeys that are meticulously planned and perfectly executed.
            <br />
            <b>Our mission</b> is to craft personalized itineraries that cater
            to your unique preferences and desires. Whether you're seeking a
            serene beach getaway, an adventurous mountain trek, or a cultural
            city tour, our dedicated team is here to turn your travel dreams
            into reality. We take pride in offering seamless and enriching
            travel experiences that allow you to explore the world with
            confidence and ease.
          </p>
          <section class="text-gray-600 body-font">
            <div class="container  py-24 mx-auto">
              <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    className="bi bi-wallet fill-blue-800 opacity-80"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                  </svg>
                </div>
                <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                  <h2 class="text-gray-900 text-lg title-font font-medium mb-2">
                    AFFORDABLE PRICE
                  </h2>
                  <p class="leading-relaxed text-base">
                    At Aarohan, we believe that travel shouldn't come with a
                    hefty price tag. We are dedicated to providing you with
                    top-quality travel resources and experiences that won't
                    break the bank.
                  </p>
                </div>
              </div>
              <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                  <h2 class="text-gray-900 text-lg title-font font-medium mb-2">
                    BEST DESTINATION
                  </h2>
                  <p class="leading-relaxed text-base">
                    At Aarohan, we are passionate about discovering and sharing
                    the most breathtaking destinations around the globe.
                  </p>
                </div>
                <div class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    class="bi bi-map fill-blue-800 opacity-80"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.5.5 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103M10 1.91l-4-.8v12.98l4 .8zm1 12.98 4-.8V1.11l-4 .8zm-6-.8V1.11l-4 .8v12.98z"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
                <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    class="bi bi-person fill-blue-800 opacity-80"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                  </svg>
                </div>
                <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                  <h2 class="text-gray-900 text-lg title-font font-medium mb-2">
                    PROFESSIONAL SERVICE
                  </h2>
                  <p class="leading-relaxed text-base ">
                    At Aarohan, we pride ourselves on offering professional
                    service that ensures a seamless and enjoyable experience for
                    every travel. Our commitment to excellence is reflected in
                    everything we do.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section class="text-gray-600 body-font">
          <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img
                class="object-cover object-center rounded"
                alt="hero"
                src={heroImage}
              />
            </div>
            <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 class="title-font sm:text-4xl text-3xl  font-bold text-opacity-80 text-gray-900  text-center w-full">
                CALLBACK FOR MORE
                <br class="hidden lg:inline-block" />
                <p class="mb-8 text-base leading-relaxed font-semibold mx-auto text-center text-opacity-75 my-2">
                  GO TRAVEL.DISCOVER. REMEMBER US!!
                </p>
              </h1>
              <section className="text-gray-600 body-font w-full h-auto">
                <div className="container  mx-auto  w-full">
                  <div className="flex flex-wrap -m-4 text-center justify-between items-stretch">
                    <div className="p-4 md:w-1/2 sm:w-1/2 w-full h-auto">
                      <div className="border-2 border-gray-200 px-4 py-6 rounded-lg h-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="text-blue-800 text-opacity-80 w-12 h-12 mb-3 inline-block"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                        <h2 className="title-font font-medium text-3xl text-gray-900">
                          20+
                        </h2>
                        <p className="leading-relaxed text-sm font-semibold">
                          Pakages
                        </p>
                      </div>
                    </div>
                    <div className="p-4 md:w-1/2 sm:w-1/2 w-full">
                      <div className="border-2 border-gray-200 px-4 py-6 rounded-lg h-full">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          className="text-blue-800 text-opacity-80 w-12 h-12 mb-3 inline-block"
                          viewBox="0 0 24 24"
                        >
                          <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                          <circle cx="9" cy="7" r="4"></circle>
                          <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                        </svg>
                        <h2 className="title-font font-medium text-3xl text-gray-900">
                          1.3K+
                        </h2>
                        <p className="leading-relaxed text-sm font-semibold">
                          Happy customer
                        </p>
                      </div>
                    </div>
                    <div className="p-4 md:w-1/2 sm:w-1/2 w-full">
                      <div className="border-2 border-gray-200 px-4 py-6 rounded-lg h-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="text-blue-800 text-opacity-80 w-12 h-12 mb-3 inline-block"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.5.5 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103M10 1.91l-4-.8v12.98l4 .8zm1 12.98 4-.8V1.11l-4 .8zm-6-.8V1.11l-4 .8v12.98z"
                          />
                        </svg>
                        <h2 className="title-font font-medium text-3xl text-gray-900">
                          100+
                        </h2>
                        <p className="leading-relaxed text-sm font-semibold">
                          Destinations
                        </p>
                      </div>
                    </div>
                    <div className="p-4 md:w-1/2 sm:w-1/2 w-full">
                      <div className="border-2 border-gray-200 px-4 py-6 rounded-lg h-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="text-blue-800 text-opacity-80 w-12 h-12 mb-3 inline-block"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                          />
                        </svg>
                        <h2 className="title-font font-medium text-3xl text-gray-900">
                          46+
                        </h2>
                        <p className="leading-relaxed text-sm font-semibold">
                          Self Crafted Pakages
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
         {/* reviews */}
         <section className="text-gray-600   body-font">
        <div className="px-5 py-24 mx-auto my-auto">
          <h1 className="text-4xl font-bold title-font text-gray-900 mb-12 text-center  tracking-tight md:text-6xl capitalize  text-clip opacity-80 ">
            Testimonials
          </h1>
          <div className="flex flex-wrap -m-4 my-8">
            <Swiper
              spaceBetween={30}
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
            >
              {isSuccess
                ? data?.testimonials.map((e, index) => {
                    return (
                      <SwiperSlide>
                        <Testimonial key={index} data={e} />
                      </SwiperSlide>
                    );
                  })
                : [0, 1, 2, 3, 4, 5, 6].map((e, index) => {
                    return (
                      <SwiperSlide>
                        <TestimonialSkeleton key={index} data={e} />
                      </SwiperSlide>
                    );
                  })}
            </Swiper>
          </div>
          <div className="flex flex-wrap -m-4 my-8">
            <Swiper
              spaceBetween={30}
              loop={true}
              centeredSlides={false}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                reverseDirection: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
            >
              {isSuccess
                ? data?.testimonials2.map((e, index) => {
                    return (
                      <SwiperSlide>
                        <Testimonial key={index} data={e} />
                      </SwiperSlide>
                    );
                  })
                : [0, 1, 2, 3, 4, 5, 6].map((e, index) => {
                    return (
                      <SwiperSlide>
                        <TestimonialSkeleton key={index} data={e} />
                      </SwiperSlide>
                    );
                  })}
            </Swiper>
          </div>
        </div>
      </section>
      <InstagramEmbed />
    </div>
  );
}

export default AboutUs;
