import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "../assects/Aarohan_Holidays_croped.png";
import { SITEDATA } from "../localData";
import "html-react-parser";
function Footer() {
  const parseHtml = require("html-react-parser").default;
  const [newsletterMsg, setNewsletterMsg] = useState("");
  const HandelNewletterSubmit = async (e) => {
    e.preventDefault();
    const mail = document.getElementById("NewsLetterEmail").value;
    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "add_user_newsletter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: mail }),
        }
      );
      const data = await response.json();
      setNewsletterMsg(data.msg);
    } catch (error) {
      console.error("Error:", error);
      setNewsletterMsg(
        "An error occurred while subscribing to the newsletter."
      );
    }
  };
  return (
    <footer className="text-gray-600 body-font bg-gray-200 pt-2">
      <div className="container px-5  mt-12 mx-auto  ">
        <div className="flex  flex-wrap gap-y-5 md:text-left text-start order-first justify-around py-5">
          <div className="md:w-2/5 w-full flex flex-col gap-y-4  ">
            <div className="flex flex-col justify-start items-start h-auto gap-x-4">
              <img src={logo} alt="" className="w-auto h-20" />
              <h2 className="title-font font-bold  text-opacity-80 text-gray-900 tracking-wide font-sans text-4xl mb-3 uppercase">
                About us
              </h2>
            </div>
            <p className=" text-justify md:text-base text-sm ">
              Welcome to Aarohan Holidays, your gateway to unforgettable
              journeys and extraordinary adventures. At Aarohan Holidays, we
              believe travel is more than just visiting new places
              <hr className="border-none my-1" />
              It's about creating memories that last a lifetime. With Aarohan
              Holidays, you can go on trips that are carefully planned and
              perfectly organized.
            </p>
            <div className="flex lg:flex-row flex-wrap gap-x-3  capitalize md:font-semibold font-medium">
              <Link to="terms_Conditions" className="cursor-pointer text-gray-500 flex flex-row  gap-x-1 items-center border-b-2 border-gray-400 hover:border-gray-500 hover:text-gray-700 w-max">
                Terms & conditions{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up-right "
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                  />
                </svg>
              </Link>
              |
              <Link to="privacy_policy" className="cursor-pointer text-gray-500 flex flex-row  gap-x-1 items-center border-b-2 border-gray-400 hover:border-gray-500 hover:text-gray-700 w-max">
                Privacy Policy{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up-right "
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                  />
                </svg>
              </Link>
              |
              <Link to="" className="cursor-pointer text-gray-500 flex flex-row  gap-x-1 items-center border-b-2 border-gray-400 hover:border-gray-500 hover:text-gray-700 w-max">
                refund Policy{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up-right "
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                  />
                </svg>
              </Link>
              |
              <Link to="sitemap" className="cursor-pointer text-gray-500 flex flex-row  gap-x-1 items-center border-b-2 border-gray-400 hover:border-gray-500 hover:text-gray-700 w-max">
                Site Map{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up-right "
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className=" md:w-2/5 w-full md:flex flex-col gap-y-4  justify-evenly md:text-base text-sm  hidden">
            <div className="">
              <h2 className="title-font font-bold  text-opacity-80 text-gray-900 tracking-wide font-sans text-4xl mb-3 uppercase">
                CONTACT Details
              </h2>
              <div className="flex flex-col gap-y-2">
                <p
                  className="tracking-wide font-medium opacity-95"
                  dangerouslySetInnerHTML={{ __html: SITEDATA.Address }}
                ></p>
                <Link
                  to={"mailto:" + SITEDATA.Email}
                  className="font-medium  text-blue-900 text-opacity-90 w-fit"
                >
                  {SITEDATA.Email}
                </Link>
                <div className=" flex flex-row flex-wrap gap-x-2 font-medium ">
                  {SITEDATA.Phone.map((e, index) => {
                    return (
                      <>
                        <Link
                          to={"tel:" + e}
                          className="  text-blue-900 text-opacity-80"
                        >
                          {" "}
                          {e}
                        </Link>
                        {index !== SITEDATA.Phone.length - 1 &&
                          parseHtml(`<span className="opacity-85">|</span>`)}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <span className="inline-flex  sm:mt-0 mt-4 justify-start sm:justify-start  gap-x-3">
              {SITEDATA.socials.map((e) => {
                return (
                  <>
                    <Link
                      to={e.link}
                      className=" text-gray-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {parseHtml(e.icon)}
                    </Link>
                  </>
                );
              })}
            </span>
           
          </div>
        </div>
      </div>
      {/* bottom footer */}
      <div className="bg-gray-100 ">
        <div className="container px-5 py-6 mx-auto flex  items-center sm:flex-row flex-col-reverse gap-y-5 border-b-2 border-dashed ">
          <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4  flex flex-col md:flex-row md:items-baseline  text-center">
            <span className="ml-3 text-xl font-serif text-gray-900 mx-2">
              Aarohan Holidays
            </span>
            <span>
              {" "}
              © {new Date().getFullYear()}.{" "}
              <span className="font-serif">All Rights Reserverd</span>{" "}
            </span>{" "}
          </p>
          {/* newsletter */}
          <span className="flex flex-col sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start md:text-start text-center ">
            <h2 className="title-font font-semibold opacity-70 text-gray-900 tracking-wide text-sm  uppercase">
              SUBSCRIBE To Our Newsletter
            </h2>
            <form action="" onSubmit={HandelNewletterSubmit}>
              <div className="flex xl:flex-nowrap md:flex-nowrap lg:flex-wrap  justify-center items-end md:justify-start w-full ">
                <div className="relative w-5/6 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2 ">
                  <input
                    type="email"
                    placeholder="johndoe@mail.com"
                    required
                    id="NewsLetterEmail"
                    name="NewsLetterEmail"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
                  />
                </div>
                <button
                  className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
                  onClick={() => {
                    setNewsletterMsg("Subscribing...");
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
            <p className="text-gray-500 text-sm mt-2 md:text-left text-center ">
              {newsletterMsg}
            </p>
          </span>
        </div>
        <div className="container px-5 py-6 mx-auto flex  items-center sm:flex-row flex-col-reverse justify-around  backdrop:blur-md  text-blue-900 text-center">
          <div className="tracking-normal text-center">
            Desing and Devloped by
            <a href="https://sudarshankakde.site"
              rel="noopener noreferrer"
              className=" ml-1 font-sans  underline-offset-4 underline font-bold"
              target="_blank"
              title="SUDARSHAN KAKDE."
            >
              Sudarshan kakde
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
