import React, { useEffect, useState } from "react";
import HeadText from "../components/HeadText";
import PakagesConatiner from "../components/PakagesConatiner";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router";
import DestinationFilter from "../components/DestinationFilter";
function Pakages(props) {
  const location = useLocation();
  const [urlParamenters, setUrlParamenters] = useState();
  const sortByValues = [
    {
      value: "popularity.desc",
      text: "Most Popular",
    },
    {
      value: "created_at.desc",
      text: "Newest First",
    },
    {
      value: "highPrice",
      text: "Highest Price",
    },
    {
      value: "lowPrice",
      text: "Lowest Price",
    },
    {
      value: "duration.asc",
      text: "Shortest First",
    },
    {
      value: "duration.desc",
      text: "Longest First",
    },
    {
      value: "upcoming_trips.asc",
      text: "Upcoming Trips",
    },
    {
      value: "manual",
      text: "Recommended",
    },
  ];
  const { data } = useQuery({
    queryKey: ["siteData"],
    queryFn: async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL + "get_siteData");
      const data = await res.json();  
      return data;
    },
    staleTime: 300000,
    cacheTime: 300000,
    refetchInterval: 300000,
    refetchIntervalInBackground: true,
  });
  const [filtered, setfiltered] = useState({
    categorie: "",
    sortby: "manual",
    destination: [],
  });

  const clearFilter = () => {
    setfiltered({
      categorie: "",
      sortby: "manual",
      destination: [],
    });
    if (urlParamenters) {
      window.history.replaceState({}, "", "pakages");
    }
  };

  function handelCategorieSelection(value) {
    setfiltered({ ...filtered, categorie: value });
  }
  function handelSortBy(value) {
    setfiltered({ ...filtered, sortby: value });
    console.log(filtered.sortby);
  }

  // mobile Ui support
  const [filterVisibility, setfilterVisibility] = useState(false);
  function toggleFilterVisibility() {
    if (filterVisibility) {
      document.getElementById("filterSection").classList.add("hidden");
      document.body.classList.replace("overflow-y-hidden", "overflow-y-auto");
      console.log(document.body.classList);

      setfilterVisibility(false);
    } else {
      document.getElementById("filterSection").classList.remove("hidden");
      setfilterVisibility(true);
      window.document.body.classList.replace(
        "overflow-y-auto",
        "overflow-y-hidden"
      );
      console.log(document.body.classList);
    }
  }
  useEffect(() => {
    const extractQueryParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const data = {};
      for (const [key, value] of urlParams.entries()) {
        const datakey = key;
        const datavalue = value;
        data[datakey] = datavalue;
      }
      setfiltered({ ...filtered, ...data });
      setUrlParamenters(data);
    };

    // Call the function when the component mounts
    extractQueryParams();
  }, [location]);
  return (
    <div className="bg-gray-50 pt-44">
      <HeadText
        title="Your Dream Vacation Awaits"
        subtitle="Ready, Set, Vacation!"
        subClass="text-lg"
      />

      <div className="sm:mx-10 mx-4 flex flex-col  h-max relative ">
        <div className="flex md:flex-row  flex-col-reverse sticky top-0  z-50 bg-gray-50 md:py-4 border border-t-0 border-x-0 border-b">
          <div className=" md:w-[25%]  w-full flex flex-row justify-between md:py-1 py-2 px-2 md:border-0 md:m-0 border-t-2 my-1">
            <strong
              className="w-full flex flex-row items-center gap-1 "
              onClick={(e) => {
                if (window.innerWidth <= 500) {
                  toggleFilterVisibility();
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-list-ul font-bold"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
                />
              </svg>
              <span className="opacity-70 font-sans">Filters</span>
            </strong>
            <button
              className="opacity-70 font-sans  bg-transparent border-2 border-gray-400 px-5 py-1 rounded-xl "
              onClick={clearFilter}
            >
              Clear
            </button>
          </div>
          <div className="md:w-[75%] flex flex-row justify-between items-center md:px-6 p-1  text-sm  w-full">
            <span className=" opacity-90 font-normal  font-sans"></span>
            <div className=" flex md:flex-row flex-col items-baseline gap-1 ">
              <span className="opacity-90 font-normal md:block hidden font-sans">
                Sort By
              </span>
              <select
                id="sort-dropdown"
                name="sortBy"
                className="bg-transparent text-emerald-600 font-semibold border-0 outline-none"
                onChange={(e) => {
                  handelSortBy(e.target.value);
                }}
              >
                {sortByValues.map((e, index) => {
                  return (
                    <option
                      value={e.value}
                      key={index}
                      selected={e.value === filtered.sortby ? true : false}
                    >
                      {e.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        {/* main conatiner */}
        <div className="flex md:flex-row flex-col min-h-screen border-gray-300 transition-all duration-1000 delay-500 ease-in-out">
          <div
            className="md:w-[25%] w-full p-5 rounded-t-3xl h-[100%] overflow-y-auto  md:h-max md:min-h-screen  md:justify-start justify-end fixed bottom-0 right-0 md:bg-inherit bg-gray-100 z-[999]  border-r md:rounded-none border-gray-300 md:p-3 hidden md:flex flex-col md:sticky md:top-16   md:z-40 "
            id="filterSection"
          >
            <div className="md:hidden flex flex-row justify-between items-center py-3 border-b-2 border-gray-400 my-2">
              <h3 className="text-2xl font-bold ">Fiters</h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
                onClick={() => {
                  toggleFilterVisibility();
                }}
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </div>

            {/* CATEGORIES */}
            <div className="w-full ">
              <p className="py-1 font-semibold opacity-80">CATEGORIES</p>
              <div className="h-[150px] overflow-y-auto">
                {data?.categorys.map((el, index) => {
                  return (
                    <>
                      <input
                        type="radio"
                        id={"id_" + el.category}
                        name="categorie"
                        value={el.category}
                        key={index}
                        className="mx-2 "
                        checked={
                          el.category === filtered.categorie ? true : false
                        }
                        onClick={() => {
                          handelCategorieSelection(el.category);
                        }}
                      />
                      <label for={"id_" + el.category} className="opacity-90">
                        {el.category}
                      </label>
                      <br />
                    </>
                  );
                })}
              </div>
            </div>
            {/* Venue */}
            <div className="w-full my-3">
              <p className="py-1 font-semibold opacity-80 uppercase">
                Destination
              </p>
              <div className="h-[150px] overflow-y-auto" id="DestinationList">
                <DestinationFilter
                  data={data}
                  filtered={filtered}
                  setfiltered={setfiltered}
                />
              </div>
            </div>
          </div>
          <div className="md:w-[75%] w-full  py-2">
            <div className="w-full  h-full flex flex-row flex-wrap py-10 justify-around   items-start gap-y-10">
              <PakagesConatiner
                filter={filtered}
                handelClearFilter={clearFilter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pakages;
