import React, { useEffect, useState } from "react";
import { DummyImage } from "./DummyImage.tsx";
import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";

function GallaryGrid(props) {
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };
  const [gallary, setGallary] = useState();
  const [page, setPage] = useState(1);
  const [allLoaded, setAllLoaded] = useState(false);
  useEffect(() => {
    InitialLoad();
  }, []);

  const InitialLoad = async () => {
    try {
      let response;
      if (props.pakage_id) {

        response = await fetch(
          process.env.REACT_APP_BASE_URL +
            props.InitialLoadPath +
            `?pakage_id=${props.pakage_id}`
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_BASE_URL + props.InitialLoadPath
        );
      }

      const data = await response.json();

      setGallary(() => {
        return data; // If it's the first page, set data directly
      });
      // if (Object.keys(data).length <= 5) {
      //   setAllLoaded(true);
      // }
      setPage(page + 1); // Increment page after getting response
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchBackendRequest = async () => {
    try {
      let response;
      if (props.pakage_id) {
        response = await fetch(
          process.env.REACT_APP_BASE_URL +
            props.InitialLoadPath +
            `?pakage_id=${props.pakage_id}&page=` +
            page
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_BASE_URL +
            props.InitialLoadPath +
            `?page=` +
            page
        );
      }
      if (response.status === 200) {
        const data = await response.json();
        setGallary((prevGallery) => {
          return [...prevGallery, ...data]; // If not, append data to existing gallery
        });
        setPage(page + 1); // Increment page after getting response
      } else {
        setAllLoaded(true);
        console.error("Error:", "no more images");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {gallary !== undefined ? (
        <LightGallery
          elementClassNames="gallaryGrid"
          onInit={onInit}
          speed={500}
          plugins={[lgThumbnail, lgZoom, lgAutoplay]}
          className=""
        >
          {gallary.map((e, index) => {
            const link = process.env.REACT_APP_MEDIA_URL + e.photos_taken;
            return (
              <a href={link} key={index} >
                <img
                  src={link}
                  alt={
                    e.photo_location.city.name +
                    "," +
                    e.photo_location.city.region.name
                  }
                  className="rounded-md"
                  width={400}
                />
              </a>
            );
          })}
        </LightGallery>
      ) : (
        // placeholder
        <>
          <div className="gallaryGrid placeholder">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(
              (e, index) => {
                return (
                  <DummyImage
                    height={getRndInteger(200, 400)}
                    width={getRndInteger(400, 400)}
                    bgColor=""
                    fgColor="red"
                    placeholder=""
                    className="border rounded-md bg-gray-400 animate-pulse mb-4"
                  />
                );
              }
            )}
          </div>
        </>
      )}

      {allLoaded ? (
        <>
          <h3 className=" text-center capitalize my-4">
            <span className="text-3xl font-serif font-semibold whitespace-normal tracking-wide">
              That's All We Have Right Now!{" "}
            </span>
            <br />
            <span className="opacity-85 py-2">
              follow us on instagram to see more photos.
            </span>
          </h3>
        </>
      ) : (
        <div className="text-center">
          <button
            onClick={fetchBackendRequest}
            className="bg-[var(--primary-color2)] text-white font-sans text-md px-6 p-2 rounded-3xl"
          >
            Load More
          </button>
        </div>
      )}
    </>
  );
}

export default GallaryGrid;

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}
