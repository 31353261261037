import React from "react";
import { Link } from "react-router-dom";

function CategoryCard(props) {
  if (props.loading) {
    return (
      <div className="p-8 md:w-[30vw] w-[90vw] md:border  border-2 bottom-2 rounded-md transition-all duration-500 ease-linear md:hover:bg-gray-200 select-none">
        <p className=" my-2 capitalize w-36 bg-gray-400 h-16 rounded-sm"></p>
        <p className="text-base opacity-80 h-24 bg-gray-300 w-full rounded-sm"></p>
      </div>
    );
  } else {
    return (
      <Link
        to={`pakages?categorie=${props.data.category}`}
        
        className="p-8 md:w-[30vw] w-[90vw] md:border  border-2 bottom-2 rounded-md transition-all duration-500 ease-linear md:hover:bg-gray-200 select-none"
      >
        <h2 className="text-3xl text-ellipsis font-medium my-2 capitalize">
          {props.data.category}
        </h2>
        <p className="text-base opacity-80 ">
          {truncate(`${props.data.sortNote}`, 200)}
        </p>
      </Link>
    );
  }
}
CategoryCard.defaultProps = {
  loading: false,
};
export default CategoryCard;

function truncate(str, limit) {
  return str.length > limit ? str.substring(0, limit) + "..." : str;
}
