import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Oval } from "react-loader-spinner";
import PakageCard from "../components/PakageCard";
import NotFound from "../components/NotFound";
function Search(props) {
  const [isSuccess, setisSuccess] = useState(false);
  const [response, setResponse] = useState();
  const [responseCode, setresponseCode] = useState();
  let { query, categories, location } = useParams();

  useEffect(() => {
    reqeustSearch();
  }, [query, categories, location]);

  const NotFoundTitle = query
    ? query + " Not Found"
    : categories
    ? `we are working on "${categories}"`
    : location
    ? `we will reach soon to "${location}"`
    : "";

  const reqeustSearch = async () => {
    if (props.type === "search") {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "search?q=" + query
      );
      const data = await response.json();
      setResponse(data);
      setresponseCode(response.status);
      setisSuccess(true);
    } else if (props.type === "categories") {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "filter?categorie=" + categories
      );
      const data = await response.json();
      setResponse(data);
      setresponseCode(response.status);
      setisSuccess(true);
    } else if (props.type === "location") {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "filter?destination=" + location
      );
      const data = await response.json();
      setResponse(data);
      setresponseCode(response.status);
      setisSuccess(true);
    }
  };

  return (
    <div className="py-16 pt-44 mx-auto flex flex-col justify-center items-center min-h-screen">
      {isSuccess ? (
        <>
          {responseCode === 404 ? (
            <NotFound
              title={NotFoundTitle}
              subtitle="no results found"
              subClass="text-sm py-2"
              
            />
          ) : (
            <>
              <div className=" flex flex-col justify-center py-10">
                <div className="container mx-auto my-auto text-center mb-12">
                  <h1 className="text-4xl font-bold title-font text-gray-900 mb-2 text-center  tracking-tight md:text-6xl capitalize  text-clip opacity-80 ">
                    Results For "{query || categories || location}"
                  </h1>
                  <small className="font-semibold text-lg text-gray-500 capitalize">
                    Ready, Set, Vacation!
                  </small>
                </div>
              </div>
              <div className="flex flex-row gap-10 flex-wrap ">
                {response.map((e, index) => {
                  return (
                    <PakageCard
                      key={index}
                      image={e.tumbnail[0].photo}
                      city={e.main_location.city.name}
                      state={e.main_location.city.region.name}
                      duration={`${e.duration.day} days ${e.duration.night} Nights`}
                      heading={e.heading}
                      desc={e.packageInfo}
                      price={e.price}
                      slug={e.slug}
                      id={e.id}
                      loop={true}
                    />
                  );
                })}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="flex flex-col gap-10 justify-center items-center">
          <Oval
            visible={true}
            height="64"
            width="64"
            color="var(--text)"
            secondaryColor="var(--primary-color2)"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
          <h3 className="text-xl font-sans font-normal opacity-80 ">
            Looking For "
            <span className="font-semibold capitalize ">{query}</span>"
          </h3>
        </div>
      )}
    </div>
  );
}

export default Search;
