import React from "react";
import HeadText from "../components/HeadText";
import { SITEDATA } from "../localData";
import "html-react-parser";
function PrivacyPolicy() {
  const parseHtml = require("html-react-parser").default;
  function PolicySection({ title, body }) {
    return (
      <div className="text-justify py-7">
        <h3 className="text-center text-lg text-balance font-semibold py-3">
          {parseHtml(title)}
        </h3>
        <p className="mb-5 text-center PolicySectionBody">{parseHtml(body)}</p>
      </div>
    );
  }
  return (
    <div className="terms-and-conditions text-justify sm:w-1/2 w-3/4 py-32 mx-auto">
      <HeadText title="privacy policy" subtitle="last updated - 28 may 2024" />
      <div className="">
        <PolicySection
          title="Introduction"
          body={`Aarohan Holidays ("we" or "us") is committed to protecting the privacy of our customers and users ("you" or "your"). This Privacy Policy outlines the types of personal information we may collect from you, how we may use and share that information, and the choices you have regarding our use of`}
        />
        <PolicySection
          title="your personal information.<br/> We Collect"
          body={`We may collect personal information from you when you visit our website, use our services, or communicate with us. This information may include:
          <br/>
          <br/>
          <ol>
          <li>Name</li>
          <li> Email address </li>
          <li>Phone number </li>
          <li>Mailing address </li>
          <li>Payment information (such as credit card number) </li>
          </ol>
          <br/>
        In addition, we may automatically collect certain information about your device and usage of our website, such as your IP address, browser type, and operating system.`}
        />

        <PolicySection
          title="Use of Information"
          body={`We may use the information we collect from you for the following purposes: <br/>
          <br/>
          <ol>
            <li>To provide and improve our services</li>
            <li>To communicate with you about your account or our services</li>
            <li>To process your payments and bookings</li>
            <li>To personalize your experience on our website</li>
            <li>To analyze and improve our website and services</li>
            <li>To comply with legal obligations</li>
          </ol>
          <br />
          We may also use your personal information for other purposes with your consent.
          `}
        />

        <PolicySection
          title="Sharing of Information"
          body={`We may share your personal information with third parties in the following circumstances: <br/> <br/>
          <ol>
            <li>With service providers who assist us in providing our services</li>
            <li>With our partners or affiliates for marketing purposes</li>
            <li>With law enforcement or other governmental authorities as required by law or to protect our legal rights</li>
          </ol>
          <br />
          In connection with a merger, acquisition, or other corporate transaction <br/>
We may also share aggregated or anonymized data that does not identify you personally.
          `}
        />

        <PolicySection
          title="Cookies and Tracking Technologies"
          body={`  We may use cookies and other tracking technologies on our website to collect information about your use of our website and to provide you with a more personalized experience. You can choose to disable cookies in your browser settings, but this may affect your ability to use our website.
          `}
        />
        <PolicySection
          title="Security"
          body={`  We take reasonable measures to protect your personal information from unauthorized access, disclosure, or misuse. However, we cannot guarantee the security of your information.
          `}
        />
        <PolicySection
          title="Your Choices"
          body={`    You may choose to opt out of receiving marketing communications from us by following the instructions in the communications or by contacting us directly. You may also update or delete your personal information by contacting us.
          `}
        />
        <PolicySection
          title="Changes to this Privacy Policy"
          body={`  We may update this Privacy Policy from time to time. The updated policy will be posted on our website with the updated effective date.`}
        />
        <PolicySection
          title="Contact Us"
          body={`If you have any questions or concerns about our Privacy Policy, please contact us at <a class="text-blue-900 opacity-90" href="mailto:${SITEDATA.Email}">${SITEDATA.Email}</a><br/> 
          <br/> By using our website and services, you consent to our collection, use, and sharing of your personal information as described in this Privacy Policy.
          `}
        />
      
        {/* ... (Rest of your TermSection components) */}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
