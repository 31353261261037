import React, { useEffect, useState } from "react";
import HeadText from "../components/HeadText";
import { Link } from "react-router-dom";
import { SITEDATA } from "../localData";
import parse from "html-react-parser";

function Contact() {
  const parseHtml = require("html-react-parser").default;

  const [Response, setResponse] = useState("");
  const [isDisabled, setisDisabled] = useState(false);
  const handleChange = (e) => {
    console.log(e.target.value.length);
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10);
    }
  };
  const handelSubmit = async (e) => {
    e.preventDefault();
    try {
      var formData = new FormData(e.target);
      if (formData.get("phone") >= 10) {
        formData.set("phone", formData.get("phone").slice(0, 10));
      }
      var data = {
        FullName: formData.get("name"),
        Email: formData.get("email"),
        phone_number: formData.get("phone"),
        Message: formData.get("message"),
      };
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "create_user_contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      //   const res = await response.json();
      console.log(response.status);
      if (response.status === 201) {
        setisDisabled("true");
      }
      setResponse(
        "thank you for contacting with us, we will be into your inbox soon!"
      );
    } catch (error) {
      console.error("Error:", error);
      setResponse("An error occurred while saving your contact information");
    }
  };

  return (
    <div className="bg-gray-50 py-44">
      <HeadText
        title="Get In Touch"
        subtitle="Share Your Queries, Recommendations, and Interests with Us"
      />
      <section className="text-gray-600 body-font relative">
        <div className="container px-5  mx-auto">
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <form className="flex flex-wrap -m-2" onSubmit={handelSubmit}>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label for="name" className="leading-7 text-sm text-gray-600">
                    Name
                  </label>
                  <input
                    disabled={isDisabled}
                    type="text"
                    id="name"
                    name="name"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out "
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    for="email"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    disabled={isDisabled}
                    type="email"
                    id="email"
                    name="email"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    for="phone"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Phone Number
                  </label>
                  <input
                    disabled={isDisabled}
                    onChange={handleChange}
                    type="number"
                    id="phone"
                    name="phone"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    for="message"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Message
                  </label>
                  <textarea
                    disabled={isDisabled}
                    id="message"
                    name="message"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                <button
                  className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                  type="submit"
                  disabled={isDisabled}
                >
                  Send
                </button>
              </div>
            </form>
            {Response ? (
              <div className="absolute top-0 backdrop-opacity-50 h-full bg-opacity-65 bg-slate-50 lg:w-1/2 md:w-2/3  flex  items-center ">
                <div className=" w-full  py-5 bg-gray-200 text-center -m-2">
                  <h2 className="uppercase font-medium opacity-90 ">
                    {Response}
                  </h2>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <hr className="container mx-auto mt-8 mb-6 w-3/5 border-t-2" />

      <div className=" flex flex-col justify-center items-center gap-y-4 gap-x-3">
        <Link
          to={"mailto:" + SITEDATA.Email}
          className="font-medium  text-blue-900 text-opacity-90 w-fit"
        >
          {SITEDATA.Email}
        </Link>
        <div className=" flex flex-row flex-wrap gap-x-2 font-medium items-baseline justify-center ">
          {SITEDATA.Phone.map((e, index) => {
            return (
              <>
                <Link
                  to={"tel:" + e}
                  className="  text-blue-900 text-opacity-80"
                >
                  {" "}
                  {e}
                </Link>{" "}
                {index !== SITEDATA.Phone.length - 1 && parseHtml(`<span className="opacity-85">|</span>`)}
              </>
            );
          })}
        </div>
        <p className="tracking-wide font-normal  text-center opacity-90">
          {parseHtml(SITEDATA.Address)}
        </p>
        <span className="inline-flex  sm:mt-0 mt-4 justify-start sm:justify-start  gap-x-3">
         
          {SITEDATA.socials.map((e) => {
            return (
              <>
                <Link
                  to={e.link}
                  className=" text-gray-700"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {parseHtml(e.icon)}
                </Link>
              </>
            );
          })}
        </span>
      </div>
    </div>
  );
}

export default Contact;
