import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../assects/logo.jpg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

import GallaryGrid from "../components/GallaryGrid";
import { Link, useParams } from "react-router-dom";
// razorpay
import useRazorpay from "react-razorpay";
import parse from "html-react-parser";

import Weather from "../components/Weather";
import { Oval } from "react-loader-spinner";

function PakageDetails(props) {
  const parse = require("html-react-parser").default;

  let { slug } = useParams();
  const [Razorpay] = useRazorpay();
  const [amount, setAmount] = useState(500);
  const { pakage_id, setPakage_id } = useState();
  // complete order
  const complete_order = (paymentID, orderID, signature) => {
    fetch(process.env.REACT_APP_URL + "order/complete/", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: "sudarshan kakde",
        email: "sid@gmail.com",
        phone: "9021767520",
        departure: "2024-07-10",
        pakage: pakage_id,
        transaction: {
          payment_id: paymentID,
          order_id: orderID,
          signature: signature,
          amount: amount,
        },
      }),
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  // const razorpayPayment = () => {
  //   fetch(process.env.REACT_APP_URL + "order/create/", {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       amount: amount,
  //       currency: "INR",
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       console.log(response.data);
  //       // get order id
  //       var order_id = response.data.id;

  //       // handle payment
  //       const options = {
  //         key: process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
  //         name: "Aarohan Holidays",
  //         description: "",
  //         image: logo,
  //         order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
  //         handler: function (response) {
  //           //complete order
  //           complete_order(
  //             response.razorpay_payment_id,
  //             response.razorpay_order_id,
  //             response.razorpay_signature
  //           );
  //         },
  //         prefill: {
  //           name: "Piyush Garg",
  //           email: "youremail@example.com",
  //           contact: "9999999999",
  //         },
  //         notes: {
  //           address: "",
  //         },
  //         theme: {
  //           color: "#3399cc",
  //         },
  //       };

  //       const rzp1 = new Razorpay(options);
  //       rzp1.on("payment.failed", function (response) {
  //         alert(response.error.code);
  //         alert(response.error.description);
  //         alert(response.error.source);
  //         alert(response.error.step);
  //         alert(response.error.reason);
  //         alert(response.error.metadata.order_id);
  //         alert(response.error.metadata.payment_id);
  //       });
  //       rzp1.open();
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  const [pakage, setPakage] = useState();
  useEffect(() => {
    InitialLoad(slug);
  }, [slug]);
  const InitialLoad = async (slug) => {
    try {
      let response;
      response = await fetch(
        process.env.REACT_APP_BASE_URL + "getPakage?slug=" + slug
      );
      const data = await response.json();
      setPakage(() => {
        return data; // If it's the first page, set data directly
      });
      setPakage_id(data.id);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      {/* <div className="w-full md:py-36">
        <div className="md:w-[75%] w-full flex flex-col justify-center items-center">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            modules={[Autoplay, Pagination, Navigation]}
            navigation={true}
            loop={true}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            className="w-full max-h-[40vh] overflow-hidden"
          >
            <SwiperSlide className="h-28 w-24 bg-red-100">
              <img
                src="https://trektales.pythonanywhere.com/DataBase/Escape/Tumbnail/kedarnath.jpeg"
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="h-28 w-24 bg-red-100">
              <img
                src="https://trektales.pythonanywhere.com/DataBase/Escape/Tumbnail/kedarnath.jpeg"
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="h-28 w-24 bg-red-100">
              <img
                src="https://trektales.pythonanywhere.com/DataBase/Escape/Tumbnail/kedarnath.jpeg"
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="h-28 w-24 bg-red-100">
              <img
                src="https://trektales.pythonanywhere.com/DataBase/Escape/Tumbnail/kedarnath.jpeg"
                alt=""
              />
            </SwiperSlide>
          </Swiper>
          <div className="flex flex-row justify-between items-center my-2 w-[90%] flex-nowrap">
            <h2 className="text-3xl font-semibold">Test</h2>
            <div className="flex flex-row justify-start gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-geo-alt"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
              </svg>
              <div className="">
                <h2 className="md:text-sm text-md font-semibold">Nagpur</h2>
                <h3 className="md:text-xs text-sm">maharashtra</h3>
              </div>
            </div>
          </div>
          <hr className="border-1 w-full" />
          <div className="sticky top-0 flex flex-row justify-between gap-4 bg-gray-100 w-full z-50 text-nowrap  overflow-scroll">
            <p data-to-scrollspy-id="first">Section 1</p>
            <p>Section 1</p>
            <p>Section 1</p>
            <p>Section 1</p>
            <p>Section 2</p>
            <p>Section 2</p>
            <p data-to-scrollspy-id="second">Section 2</p>
          </div>
          <div className="">
            <ScrollSpy updateHistoryStack={false}>
              <div
                id="first"
                className="h-screen my-auto text-center bg-gray-100"
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
                dolores veritatis doloremque fugit. Soluta aperiam atque
                inventore deleniti, voluptatibus non fuga eos magni natus vel,
                rerum excepturi expedita. Tempore, vero!
              </div>
              <div
                id="second"
                className="h-screen my-auto text-center bg-green-200"
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
                dolores veritatis doloremque fugit. Soluta aperiam atque
                inventore deleniti, voluptatibus non fuga eos magni natus vel,
                rerum excepturi expedita. Tempore, vero!
              </div>
            </ScrollSpy>
          </div>
        </div>
        <div className="w-[25%]"></div>
      </div> */}

      <>
        {pakage !== undefined ? (
          <div className="bg-gray-50/60 md:px-20 max-w-screen overflow-x-hidden md:py-36 py-24">
            <div className="flex md:flex-row flex-col-reverse py-6 md:w-full w-[95%] gap-y-8  mx-auto">
              <div className="md:w-[60%] w-full">
                <div className="container px-4 md:px-6">
                  <div className="grid gap-6 md:gap-12 lg:gap-24 xl:gap-32">
                    <div className="grid gap-4 lg:gap-8">
                      <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl/none">
                        {pakage.heading}
                      </h1>
                      <div className="grid gap-4 lg:gap-8">
                        <p className="text-gray-500">
                          <Weather location={pakage.main_location} />
                        </p>
                      </div>
                      <div className="flex items-center flex-wrap gap-4">
                        <div className="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="w-5 h-5"
                          >
                            <rect
                              width="18"
                              height="18"
                              x="3"
                              y="4"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="16" x2="16" y1="2" y2="6"></line>
                            <line x1="8" x2="8" y1="2" y2="6"></line>
                            <line x1="3" x2="21" y1="10" y2="10"></line>
                          </svg>
                          <span className="text-gray-500">
                            {pakage.duration.day} days, {pakage.duration.night}{" "}
                            nights
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="w-5 h-5"
                          >
                            <line x1="2" x2="5" y1="12" y2="12"></line>
                            <line x1="19" x2="22" y1="12" y2="12"></line>
                            <line x1="12" x2="12" y1="2" y2="5"></line>
                            <line x1="12" x2="12" y1="19" y2="22"></line>
                            <circle cx="12" cy="12" r="7"></circle>
                          </svg>
                          <span className="text-gray-500">
                            {pakage.main_location.city.name},{" "}
                            {pakage.main_location.city.region.name}
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-bookmarks"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1z" />
                            <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1" />
                          </svg>
                          <span className="text-gray-500">
                            {pakage.category.category}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="grid items-start gap-6 md:grid-cols-2 lg:gap-8">
                      <div className="grid items-start gap-4 md:gap-6">
                        <div className="space-y-2">
                          {pakage.price.discount ? (
                            <>
                              <p className="text-2xl font-semibold">
                                ₹&nbsp;{pakage.price.discounted_price} <span className="text-sm capitalize opacity-65">Per Person</span>
                              </p>
                              <p className="text-xl leading-none text-gray-500 line-through">
                                ₹&nbsp;{pakage.price.orignal_price}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="text-2xl font-semibold">
                                ₹&nbsp;{pakage.price.orignal_price} <span className="text-sm capitalize opacity-65">Per Person</span>
                              </p>
                            </>
                          )}
                        </div>
                        <div className="grid items-start">
                          <ul className="grid gap-1">
                            <li className="flex  gap-2 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-star-fill opacity-70"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              Instant Confirmation
                            </li>
                            <li className="flex  gap-2 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-star-fill opacity-70"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              100% Satisfaction Guarantee
                            </li>
                            <li className="flex  gap-2 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-star-fill opacity-70"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                              Secure & Easy Booking
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-[40%] gap-6 flex flex-col w-full justify-center items-center my-auto relative">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  modules={[Autoplay, Navigation]}
                  navigation={true}
                  loop={true}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="w-full h-[40vh] overflow-hidden drop-shadow-2xl shadow-gray-700 shadow-lg rounded-lg select-none"
                >
                  {pakage.tumbnail.map((e) => {
                    const link = process.env.REACT_APP_MEDIA_URL + e.photo;
                    return (
                      <SwiperSlide key={Math.random()}>
                        <img
                          src={link}
                          alt={pakage.main_location.city.name}
                          className="mx-auto w-full h-full object-center object-cover  rounded-lg"
                        />
                      </SwiperSlide>
                    );
                  })}
                  <SwiperSlide className=" ">
                    <span
                      className="h-full text-center text-xl  uppercase w-full justify-center items-center flex hover:underline cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.scrollTo({
                          top: document.querySelector("#photos").offsetTop,
                          behavior: "smooth",
                        });
                      }}
                    >
                      See More photos
                    </span>
                  </SwiperSlide>
                </Swiper>
                <Link
                  to={`/pakage/booking/${pakage.slug}`}
                  state={{
                    pakage: pakage,
                  }}
                  className="bg-green-500 px-16 py-3 rounded-md text-2xl text-white hover:bg-green-600 duration-200 ease-in font-medium "
                  // onClick={() => {
                  //   razorpayPayment();
                  // }}
                >
                 Send Inquire
                </Link>
              </div>
            </div>
            <div className="border-t  border-gray-700 ">
              <div className="container  px-4 md:px-6 my-8">
                <div className="grid  gap-6 md:gap-12 lg:gap-24 xl:gap-32">
                  <p className="text-sm text-start first-letter:uppercase opacity-80">
                    {parse(String(pakage.packageInfo))}
                  </p>
                </div>
              </div>
            </div>

            <div className="border-t border-gray-700 ">
              <div className="container px-4 md:px-6 mt-8 " id="photos">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-2">
                    <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl">
                      Photo Gallery
                    </h2>
                    <p className="text-gray-500 md:text-xl/relaxed lg:text/base/relaxed xl:text-xl/relaxed ">
                      Immerse yourself in the beauty of{" "}
                      {pakage.main_location.city.name} with these stunning
                      photos.
                    </p>
                  </div>
                  <GallaryGrid
                    InitialLoadPath="get_gallery"
                    pakage_id={pakage.id}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-auto h-screen flex  flex-col  gap-4 justify-center items-center">
            <Oval
              visible={true}
              height="64"
              width="64"
              color="var(--text)"
              secondaryColor="var(--primary-color2)"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperclassName=""
            />
            <h2 className="text-lg font-bold opacity-80 text-[var(--text)]">Loading Pakage Details</h2>
          </div>
        )}
      </>
      <></>
    </>
  );
}

export default PakageDetails;
