import React, { useEffect, useState } from "react";
import GallaryGrid from "../components/GallaryGrid";
import HeadText from "../components/HeadText";
function Gallery() {

  return (
    <div className="bg-gray-50 pt-44">
      <HeadText
        title="Picture Yourself Here"
        subtitle="Warning: May Cause tour Bookings"
      />
     
      <div className="sm:mx-10 mx-4 pb-20">
          <GallaryGrid InitialLoadPath="get_gallery" />
       
      </div>
    </div>
  );
}

export default Gallery;
