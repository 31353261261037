import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { HomePageSilder } from "../localData";

const images = HomePageSilder;

const Slider = ({data,isSuccess}) => {
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const goToPrevious = () => {
    setIsFading(true);
    setTimeout(() => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this timeout to the transition duration
  };
  const sliderDots = Array.from({ length: images.length }, (_, i) => i + 1);
  const goToNext = () => {
    setIsFading(true);
    setTimeout(() => {
      const isLastSlide = currentIndex === images.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this timeout to the transition duration
  };
  const goToPage = (index) => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex(index);
      setIsFading(false);
    }, 500);
  };

  // Auto-change function
  useEffect(() => {
    const searchForm = document.getElementById("search-form");
    searchForm?.addEventListener("submit", (event) => {
      event.preventDefault();
      const searchQuery = document.getElementById("Search").value;
      navigate("/search/" + searchQuery);
    });

    if (images[currentIndex].formated) {
      const interval = setInterval(() => {
        goToNext();
      }, 7000); // Change slide every 7 seconds
      return () => clearInterval(interval); // Cleanup interval on component unmount
    } else {
      const interval = setInterval(() => {
        goToNext();
      }, 5000); // Change slide every 5 seconds
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [currentIndex, navigate]);
  return (
    <div className="relative w-full h-full overflow-hidden bg-zinc-300">
      <div
        className={`absolute inset-0 bg-cover bg-center slide`}
        style={{
          backgroundImage: `url(${images[currentIndex]})`,
          transition: "opacity .75s ease-in-out",
        }}
      >
        <div
          className={`absolute inset-0 bg-black  ${
            isFading ? " opacity-80" : "opacity-50"
          }`}
          style={{
            transition: "opacity .75s ease-in-out",
          }}
        ></div>
        <div
          className={`absolute inset-0 flex flex-col items-center justify-center  md:m-24 mt-24 text-center mx-4 text-white p-4 gap-5 select-none md:h-auto h-[70%] `}
          id="HeadTextSection"
        >
          <h2 className="text-4xl md:text-6xl font-semibold mx-auto md:w-[70%] w-[90%]  mb-4 opacity-95 ">
            Find Your Perfect Escape!
          </h2>
          <div className="block rounded-lg shadow-lg bg-glass px-6 py-12 md:px-12 text-gray-800 md:w-[65vw]">
            <form className="flex flex-col gap-3" id="search-form">
              <div className="flex flex-row   w-full rounded px-3 py-1.5 text-base font-normal  bg-white bg-clip-padding">
                <input
                  type="text"
                  style={{ outline: "none" }}
                  className="form-control block w-full .noborder  px-3 py-1.5 text-base font-normal border-none outline-none transition ease-in-out  "
                  placeholder="Search Destinations,Tours,Activites"
                  name="query"
                  id="Search"
                  required
                />
                <button type="submit" className="px-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </button>
              </div>
              <div className="text-start  block">
                <div className="text-[var(--text-2)] capitalize text-start text-sm mb-2">
                  top Searches :
                </div>
                <div className="">
                  {isSuccess
                    ? data?.search.map((e, index) => {
                        return (
                          <span
                            className="Popular_search capitalize"
                            key={index}
                          >
                            <Link to={"/search/" + e.search}>{e.search}</Link>
                          </span>
                        );
                      })
                    : [1, 2, 3, 4, 5].map((e, index) => {
                        return (
                          <span
                            className="Popular_search capitalize animate-pulse transition-all duration-1000  "
                            key={index}
                          >
                            <span className="px-9 h-2 "></span>
                          </span>
                        );
                      })}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="absolute bottom-5  flex flex-row justify-between w-full items-baseline">
        <div className="md:ml-10 ">
          <button
            onClick={goToPrevious}
            className=" bg-transparent border backdrop-blur-sm hover:bg-gray-200 hover:text-gray-800 text-white p-2 transition-all duration-300 ease-in-out rounded-full mx-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
              />
            </svg>
          </button>
          <button
            onClick={goToNext}
            className="bg-transparent border backdrop-blur-sm hover:bg-gray-200 hover:text-gray-800 text-white p-2 transition-all duration-300 ease-in-out rounded-full mx-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
              />
            </svg>
          </button>
        </div>
        <span className="flex flex-row gap-1 ">
          {sliderDots.map((current, i) => {
            return (
              <div key={i} className={`mx-2  ${currentIndex === i ? "" : ""} `}>
                {currentIndex === i ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-circle-fill fill-slate-200 cursor-pointer"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-circle fill-slate-200 cursor-pointer"
                    viewBox="0 0 16 16"
                    onClick={() => {
                      goToPage(i);
                    }}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  </svg>
                )}
              </div>
            );
          })}
        </span>
        <span className="capitalize border rounded-3xl px-8 py-2 bg-gray-100 text-black font-semibold mt-10 mr-10  md:block hidden">
          {currentIndex + 1} / {images.length}
        </span>
      </div>
    </div>
  );
};

export default Slider;
