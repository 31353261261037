import React from "react";
import TourForm from "../components/TourForm";
import HeadText from "../components/HeadText";

function PlanYourself() {
  return (
    <>
      <div className="bg-gray-50 pt-44 pb-20">
        <HeadText
          title="Plan Your Next trip"
          subtitle="Please Use The Below Form To Request A Customized And Personalized Travel Plan EXCLUSIVELY FOR YOU!"
        />
        <div className="w-[90%] mx-auto">
          <TourForm />
        </div>
      </div>
    </>
  );
}

export default PlanYourself;
