import React from "react";
import NotFound from "../components/NotFound";

function PageNotFound() {
  return (
    <div>
      <NotFound
        title="Page Not Found"
        subtitle="requested page is not avalible"
        subClass="text-lg py-2"
      />
    </div>
  );
}

export default PageNotFound;
