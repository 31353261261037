import React, { useState } from "react";

function DestinationFilter({ data, filtered, setfiltered }) {
    const [searchQuery, setSearchQuery] = useState("");
  
    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };
  
    const handelDestinationSelection = (event, destination) => {
      const isChecked = event.target.checked;
      if (isChecked && !filtered.destination.includes(destination)) {
        setfiltered((prevFiltered) => ({
          ...prevFiltered,
          destination: [...prevFiltered.destination, destination],
        }));
      } else if (!isChecked) {
        setfiltered((prevFiltered) => ({
          ...prevFiltered,
          destination: prevFiltered.destination.filter(
            (item) => item !== destination
          ),
        }));
      }
      console.log(filtered);
    };
  
    const filteredDestinations = data?.destination.filter((item) =>
      item.city.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    return (
      <div>
        <input
          type="text"
          placeholder="Search.."
          id="myInput"
          className="w-full p-2 border border-gray-300 mb-2 outline-none"
          value={searchQuery}
          onChange={handleSearchChange}
        />
  
        {filteredDestinations?.map((item, index) => (
          <React.Fragment key={index}>
            <input
              type="checkbox"
              id={"destination_id_" + item.city.name.toString()}
              name="destination"
              value={item.city.name}
              className="mx-2 "
              onChange={(event) =>
                handelDestinationSelection(event, item.city.name)
              }
              checked={filtered.destination.includes(item.city.name)}
            />
            <label
              htmlFor={"destination_id_" + item.city.name.toString()}
              className="capitalize"
            >
              {item.city.name}
            </label>
            <br />
          </React.Fragment>
        ))}
      </div>
    );
  }
  
  export default DestinationFilter;
  