import React from "react";
import { Link } from "react-router-dom";

function SitemapLinks(props) {
  return (
    <div className="">
      <h3 className="text-2xl font-medium text-gray-900 py-2">
        <span className="text-indigo-600 capitalize">{props.title}</span>
      </h3>
      <p className="mt-1 text-base text-gray-500 mx-2 ">
        <ul className="">
          {props.type === "category"
            ? props.links.map((e) => {
                return (
                  <li className="my-1.5 font-normal  ">
                    <Link
                      to={`/pakages?categorie=${e.category}`}
                      className="cursor-pointer capitalize text-gray-500 flex flex-row  gap-x-1 items-center  hover:font-semibold  hover:border-gray-500 hover:text-gray-700 w-max"
                    >
                      {e.category}
                    </Link>
                  </li>
                );
              })
            : props.type === "destination"
            ? props.links.map((e) => {
                return (
                  <li className="my-1.5 font-normal  ">
                    <Link
                      to={`/pakages?destination=${e.city.name}`}
                      className="cursor-pointer capitalize text-gray-500 flex flex-row  gap-x-1 items-center  hover:font-semibold  hover:border-gray-500 hover:text-gray-700 w-max"
                    >
                      {e.city.name}
                    </Link>
                  </li>
                );
              })
            : props.type === "destination"
            ? props.links.map((e) => {
                return (
                  <li className="my-1.5 font-normal  ">
                    <Link
                      to={`/pakages?destination=${e.city.name}`}
                      className="cursor-pointer capitalize text-gray-500 flex flex-row  gap-x-1 items-center  hover:font-semibold  hover:border-gray-500 hover:text-gray-700 w-max"
                    >
                      {e.city.name}
                    </Link>
                  </li>
                );
              })
            : props.type === "pakage"
            ? props.links.map((e) => {
                return (
                  <li className="my-1.5 font-normal  ">
                    <Link
                      to={"/pakage/detail/" + e.slug}
                      className="cursor-pointer capitalize text-gray-500 flex flex-row  gap-x-1 items-center  hover:font-semibold  hover:border-gray-500 hover:text-gray-700 w-max"
                    >
                      {e.heading}
                    
                    </Link>
                  </li>
                );
              })
            : ""}
          {props.type === undefined
            ? Object.entries(props.links).map(([key, value]) => {
                return (
                  <li className="my-2 font-normal ">
                    <Link
                      to={value}
                      className="cursor-pointer capitalize text-gray-500 flex flex-row  gap-x-1 items-center  hover:font-semibold  hover:border-gray-500 hover:text-gray-700 w-max"
                    >
                      {key}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-up-right "
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                        />
                      </svg>
                    </Link>
                  </li>
                );
              })
            : ""}
        </ul>
      </p>
    </div>
  );
}

export default SitemapLinks;
