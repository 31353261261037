import React from "react";
import HeadText from "../components/HeadText";
import { useContext } from "react";
import { MyContext } from "../MyContext";
import SitemapLinks from "../components/SitemapLinks";
import { useQuery } from "@tanstack/react-query";

function Sitemap() {
  const { Links } = useContext(MyContext);
  const { data, isSuccess } = useQuery({
    queryKey: ["siteData"],
    queryFn: () => {
      console.log(process.env);
      return fetch(process.env.REACT_APP_BASE_URL + "get_siteData")
        .then((res) => res.json())
        .then((res) => res);
    },
    staleTime: 900000,
    refetchInterval: 900000,
    refetchIntervalInBackground: true,
  });
  return (
    <div className="bg-gray-50 py-44">
      <HeadText title="Sitemap" subtitle="" />
      <div className="sm:mx-10 mx-4 -py-20 ">
        <hr className="border  mb-5" />
        <div className="flex flex-row flex-wrap md:justify-between gap-x-20 gap-y-10 w-4/5   mx-auto">
          <SitemapLinks links={Links} title={"Pages"} />
          {isSuccess ? (
            <>
              <SitemapLinks
                links={data.categorys}
                title={"categories"}
                type={"category"}
              />
              <SitemapLinks
                links={data.destination}
                title={"destination"}
                type={"destination"}
              />
              <SitemapLinks
                links={data.pakages}
                title={"Pakages"}
                type={"pakage"}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Sitemap;
