import React from "react";
import { Link } from "react-router-dom";
const PakageCard = (props) => {
  return (
    <div
      className=" backdrop-blur-sm text-black rounded-lg p-5 flex flex-col items-center justify-between max-w-sm group gap-2 border-2 
    md:w-[50vw] lg:w-[28vw] w-[98vw] mx-auto"
    >
      <div className="h-[30vh] w-full overflow-hidden rounded-md  relative">
        <img
          src={`${process.env.REACT_APP_MEDIA_URL}${props.image}`}
          alt=""
          className=" object-cover w-full h-[30vh] "
        />
        <div className="absolute w-full bottom-0 flex flex-row text-black justify-between  items-end p-2 pt-7  bg-gradient-to-t from-white to-transparent">
          <div className="flex flex-row justify-start gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-geo-alt"
              viewBox="0 0 16 16"
            >
              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
            </svg>
            <div className="">
              <h2 className="md:text-sm text-md font-semibold">{props.city}</h2>
              <h3 className="md:text-xs text-sm">{props.state}</h3>
            </div>
          </div>
          <div className="flex flex-row justify-start gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-clock-history"
              viewBox="0 0 16 16"
            >
              <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
              <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
              <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
            </svg>
            <p className="md:text-xs text-md font-normal">{props.duration}</p>
          </div>
        </div>
      </div>
      <div className="w-full">
        <h2 className="text-lg mt-2 font-medium ">
          {truncate(props.heading, 40)}
        </h2>
      </div>
      <div className="flex justify-between w-full items-center my-2">
        <div className="flex flex-row items-baseline gap-2 justify-start">
          {props.price.discount ? (
            <>
              <p className="text-sm font-medium line-through opacity-70">
                Rs.{props.price.orignal_price}
              </p>
              <p
                className="font-medium rounded-md text-md cursor-help"
                title="Prices may vary depending on your departure location."
              >
                @ Rs.{props.price.discounted_price}
              </p>
            </>
          ) : (
            <>
              <p
                className="font-medium rounded-md text-md cursor-help"
                title="Prices may vary depending on your departure location."
              >
                @ Rs.{props.price.orignal_price}
              </p>
            </>
          )}
        </div>
        <Link
          to={`/pakage/detail/${props.slug}`}
          className="border p-4 py-2 rounded-3xl text-sm flex flex-row flex-nowrap justify-center items-center gap-2 bg-zinc-100 hover:bg-[var(--primary-color2)]
          hover:border-[var(--primary-color2)] hover:opacity-80 hover:text-white hover:font-semibold"
        >
          Check Out
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default PakageCard;

PakageCard.defaultProps = {
  image: "",
  city: "",
  state: "",
  duration: "",
  heading: "",
  desc: "",
  price: "",
  loop: true,
};
function truncate(str, limit) {
  try {
    return str.length > limit ? str.substring(0, limit) + "..." : str;
  } catch (e) {
    return str;
  }
}
