// import Pakages from "../pages/Pakages";
// import logo from "../assects/logo.jpg";
// import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
// import {  useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Oval } from "react-loader-spinner";

// razorpay
import useRazorpay from "react-razorpay";
function BookingSection() {
  const addDays = (date, days) => {
    const newdate = new Date();
    newdate.setDate(newdate.getDate() + days);
    return newdate;
  };
  const dateFormatter = (date) => {
    var dateString = date;
    var dateObject = new Date(dateString);

    var year = dateObject.getFullYear();
    var month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
    var day = ("0" + dateObject.getDate()).slice(-2);

    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  };
  // const location = useLocation();
  // const [Razorpay] = useRazorpay();
  const [PayemtError, setPayemtError] = useState("dont works");
  let { slug } = useParams();
  const [pakage, setPakage] = useState(null);
  const [PayemntStatus, setPayemntStatus] = useState();
  const [selectedDate] = useState(addDays(new Date(), 5));
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (step === 1 || step === 2) {
      if (PayemntStatus !== undefined) {
        setPayemntStatus(undefined);
      }
    }
  }, [step]);
  const [errors, setErrors] = useState({});
  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    departure: dateFormatter(selectedDate),
    number_of_guests: "1",
    departure_location: "",
    pakage: "",
    pakage_url: window.location.origin + "/pakage/detail/" + slug,
    transaction: null,
  });

  // functions
  useEffect(() => {
    if (pakage === null) {
      const fetchData = async () => {
        const res = await fetch(
          process.env.REACT_APP_BASE_URL + "getPakage?slug=" + slug
        );
        const data = await res.json();
        setFormData({ ...formdata, pakage: data.id });
        setPakage(data);
      };
      fetchData();
    }
  }, [pakage, slug]);

  if (pakage !== null) {
    const MyContainer = ({ className, children }) => {
      return (
        <div className="border p-3 bg-gray-100 rounded-lg w-[auto] flex flex-col text-center gap-4 ">
          <CalendarContainer className={className}>
            <div style={{ position: "relative" }} className="max-w-fit">
              {children}
            </div>
          </CalendarContainer>
        </div>
      );
    };

    const conatiner = () => {
      if (step === 1) {
        return (
          <div className="flex  md:flex-row flex-col gap-5 md:items-end items-center">
            <DatePicker
              minDate={addDays(new Date(), 5)}
              showIcon
              selected={selectedDate}
              onChange={(date) => {
                setFormData({ ...formdata, departure: dateFormatter(date) });
                setStep(2);
              }}
              calendarContainer={MyContainer}
              shouldCloseOnSelect={false}
              inline
            />
            <div className="max-w-lg flex gap-2 flex-col">
              <img
                src="https://www.trekpanda.in/assets/itinerary/store_front/departure-date-step-arrow-icon-0436950f1e39158cb7ab3b7ba10f2794.png"
                alt=""
                className="w-32 h-32 md:block hidden"
              />
              <span className="text-3xl font-semibold ml-7 text-[var(--primary-color2)]">
                Pick a departure date
              </span>
              <p className="text-sm ml-7">
                You can request for a departure in case it is sold-out or we're
                not normally operating on that date. We will try to accommodate
                your request, if we can.
              </p>
            </div>
          </div>
        );
      } else if (step === 2) {
        return (
          <div className="">
            <section className="text-gray-600 body-font relative">
              <div className="container px-5  mx-auto">
                <div className="flex flex-col text-center w-full mb-12">
                  <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                    Contact Details
                  </h1>
                  <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                    Who do we communicate with about this booking?
                  </p>
                </div>
                <form
                  className="lg:w-1/2 md:w-2/3 mx-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="flex flex-wrap -m-2">
                    <div className="p-2 w-1/2">
                      <div className="relative">
                        <label
                          for="name"
                          className="leading-7 text-sm text-gray-600"
                        >
                          Name
                        </label>
                        <input
                          onChange={handelFormChange}
                          value={formdata.name}
                          type="text"
                          id="name"
                          name="name"
                          required
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.name && (
                          <div className="error">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="p-2 w-1/2">
                      <div className="relative">
                        <label
                          for="email"
                          className="leading-7 text-sm text-gray-600"
                        >
                          Email
                        </label>
                        <input
                          onChange={handelFormChange}
                          type="email"
                          id="email"
                          name="email"
                          value={formdata.email}
                          required
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                        {errors.email && (
                          <div className="error">{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="p-2 w-1/2">
                      <div className="relative mb-3">
                        <label
                          for="phone"
                          className="leading-7 text-sm text-gray-600"
                        >
                          Phone number
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-300 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white  focus:border-indigo-500 focus:ring-indigo-200 focus:ring-2 text-base outline-none transition-colors duration-200 ease-in-out"
                          id="grid-Phone"
                          type="number"
                          name="phone"
                          placeholder="9021000888"
                          value={formdata.phone}
                          onChange={handelFormChange}
                          onWheel={(e) => e.currentTarget.blur()}
                          inputMode="numeric" // Add this line
                          required
                        />
                        {errors.phone && (
                          <div className="error">{errors.phone}</div>
                        )}
                      </div>
                    </div>
                    <div className="p-2 w-1/2">
                      <div className="relative mb-3">
                        <label
                          for="number_of_guests"
                          className="leading-7 text-sm text-gray-600 "
                        >
                          Number of guests
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-300 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white  focus:border-indigo-500 focus:ring-indigo-200 focus:ring-2 text-base outline-none transition-colors duration-200 ease-in-out"
                          id="grid-Phone"
                          type="number"
                          name="number_of_guests"
                          placeholder="1 Or More"
                          value={formdata.number_of_guests}
                          onChange={(e) => {
                            handelFormChange(e);
                          }}
                          onWheel={(e) => e.currentTarget.blur()}
                          inputMode="numeric" // Add this line
                          min={1}
                          required
                        />
                        {errors.number_of_guests && (
                          <div className="error">{errors.number_of_guests}</div>
                        )}
                      </div>
                    </div>
                    <div className="p-2 w-full">
                      <div className="relative mb-3">
                        <label
                          for="numberOfPepoles"
                          className="leading-7 text-sm text-gray-600 "
                        >
                          Depature location
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-300 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white  focus:border-indigo-500 focus:ring-indigo-200 focus:ring-2 text-base outline-none transition-colors duration-200 ease-in-out"
                          id="grid-Phone"
                          type="text"
                          name="departure_location"
                          placeholder="Ex. Chh ShambajiNagar, Maharashtra"
                          value={formdata.departure_location}
                          onChange={(e) => {
                            handelFormChange(e);
                          }}
                          onWheel={(e) => e.currentTarget.blur()}
                          inputMode="text" // Add this line
                          required
                        />
                        {errors.departure_location && (
                          <div className="error">
                            {errors.departure_location}
                          </div>
                        )}
                      </div>
                    </div>

                    <hr className="w-full m-2 " />
                    <div className="p-2 w-full">
                      <div className="relative">
                        <label
                          for="message"
                          className="leading-7 text-sm text-gray-600 "
                        >
                          <span className="font-semibold">
                            Any special requests / comments?
                          </span>
                          <br />
                          <small>
                            Note: We try our best to accommodate special
                            requests, but it may not always be possible to
                            fulfil them.
                          </small>
                        </label>
                        <textarea
                          name="comment"
                          id=""
                          cols="30"
                          rows="10"
                          value={formdata.comment}
                          onChange={handelFormChange}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                        ></textarea>
                      </div>
                    </div>
                    <div className="p-2 w-full">
                      <button
                        type="submit"
                        className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                        onClick={handelDetailSubmission}
                      >
                        Send Inquiry
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        );
      } else if (step === 3 || step === 4) {
        return (
          <div className="min-h-[50vh] flex justify-center ">
            <div className="flex flex-col justify-center items-center gap-5 text-2xl capitalize font-semibold text-center">
              {PayemntStatus === undefined ? (
                <>
                  <Oval
                    visible={true}
                    height="64"
                    width="64"
                    color="var(--text)"
                    secondaryColor="var(--primary-color2)"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperclassName=""
                  />
                  inquiry in progress
                </>
              ) : PayemntStatus ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="175"
                    height="175"
                    className="bi bi-check2-circle fill-green-500"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                  </svg>
                  <h2>inquiry Saved Succesfully</h2>
                  <small className="text-lg  font-normal text-opacity-85 ">
                    check your email inbox for more details
                  </small>
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="175"
                    height="175"
                    className="bi bi-x-circle-fill fill-red-400"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                  </svg>

                  <h2>Failed To Save inquiry </h2>
                  {PayemtError ? (
                    <small className="text-sm ">Deu to - {PayemtError}</small>
                  ) : (
                    <></>
                  )}
                  <button
                    className="uppercase text-lg  text-blue-500 "
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    retry
                  </button>
                </>
              )}
            </div>
          </div>
        );
      }
    };
    const checkSteps = (stepNumber) => {
      if (stepNumber < step) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="var(--primary-color2)"
            className="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
        );
      } else if (stepNumber == step) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="var(--primary-color2)"
            className="bi bi-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
          </svg>
        );
      } else {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="var(--primary-color2)"
            className="bi bi-circle-fill opacity-80"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
        );
      }
    };
    const sendInquireRequest = () => {
      fetch(process.env.REACT_APP_URL + "order/complete/", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...formdata,
          transaction: {
            amount: pakage.price.orignal_price,
          },
        }),
      })
        .then((response) => {
          if (response.status === 201) {
            setPayemntStatus(true);
            setStep(4);
          } else if (response.status === 402) {
            setPayemntStatus(false);
          }
        })
        .catch((error) => {
          console.log(error.response.body);
        });
    };
    // const razorpayPayment = () => {
    //   fetch(process.env.REACT_APP_URL + "order/create/", {
    //     method: "post",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       amount: pakage.price.orignal_price,
    //       currency: "INR",
    //     }),
    //   })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       console.log(response.data);
    //       // get order id
    //       var order_id = response.data.id;

    //       // handle payment
    //       const options = {
    //         key: process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
    //         name: "Aarohan Holidays",
    //         description: "",
    //         image: logo,
    //         order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
    //         handler: function (response) {
    //           //complete order
    //           complete_order(
    //             response.razorpay_payment_id,
    //             response.razorpay_order_id,
    //             response.razorpay_signature
    //           );
    //         },
    //         prefill: {
    //           name: formdata.name,
    //           email: formdata.email,
    //           contact: formdata.phone,
    //         },
    //         notes: {
    //           address: "",
    //         },
    //         theme: {
    //           color: "#3399cc",
    //         },
    //       };

    //       const rzp1 = new Razorpay(options);
    //       rzp1.on("payment.failed", function (response) {
    //         setPayemntStatus(false);
    //         setPayemtError(response.error.reason);
    //       });
    //       rzp1.open();
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // };
    // const complete_order = (paymentID, orderID, signature) => {
    //   console.log(formdata);
    //   fetch(process.env.REACT_APP_URL + "order/complete/", {
    //     method: "post",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       ...formdata,
    //       transaction: {
    //         payment_id: paymentID,
    //         order_id: orderID,
    //         signature: signature,
    //         amount: pakage.price.orignal_price,
    //       },
    //     }),
    //   })
    //     .then((response) => {
    //       if (response.status === 201) {
    //         setPayemntStatus(true);
    //         setStep(4);
    //       } else if (response.status === 402) {
    //         setPayemntStatus(false);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response.body);
    //     });
    // };
    const validateForm = () => {
      let errors = {};
      if (!formdata.name.trim()) {
        errors.name = "Name is required";
      }
      if (!formdata.email.trim()) {
        errors.email = "Email is required";
      } else if (!/^\S+@\S+\.\S+$/.test(formdata.email)) {
        errors.email = "Invalid email format";
      }
      if (!formdata.phone.trim()) {
        errors.phone = "Phone number is required";
      } else if (!/^\d{10}$/.test(formdata.phone)) {
        errors.phone = "Phone number must be 10 digits";
      }
      if (!formdata.departure_location.trim()) {
        errors.departure_location = "depature location is required";
      }
      if (!formdata.number_of_guests.trim()) {
        errors.number_of_guests = "number of guests are required";
      } else if (
        formdata.number_of_guests === 0 ||
        formdata.number_of_guests === "0"
      ) {
        errors.number_of_guests = "number of guest must be greater than zero";
      }

      setErrors(errors);
      return Object.keys(errors).length === 0;
    };
    const handelDetailSubmission = (e) => {
      if (validateForm()) {
        // Form data is valid, proceed with submission
        console.log(formdata);
        sendInquireRequest();
        // razorpayPayment();
        setStep(3);
      } else {
        // Form data is invalid, display error messages
        console.log("Form validation failed");
      }
    };
    const handelFormChange = (e) => {
      const { name, value } = e.target;
      if (name === "phone") {
        if (formdata.phone.lenght <= 10) {
          setFormData({ ...formdata, phone: value });
        } else {
          setFormData({ ...formdata, phone: value.slice(0, 10) });
        }
      } else {
        setFormData({ ...formdata, [name]: value });
      }
    };

    return (
      <div className="pt-32 pb-20">
        <div className="flex flex-col my-30 gap-20  items-center justify-center">
          <div className="flex flex-row justify-between md:w-[40%] w-[90%]">
            <span
              className="flex flex-col justify-center items-center text-sm capitalize gap-1 font-semibold cursor-pointer"
              onClick={() => {
                if (step === 2) {
                  setStep(1);
                }
              }}
            >
              {checkSteps(1)}
              Dates
            </span>

            <span
              className="flex flex-col justify-center items-center text-sm capitalize gap-1 font-semibold cursor-pointer"
              // onClick={() => {
              //   if (step > 2 ) {
              //     setStep(2);
              //   }
              // }}
            >
              {checkSteps(2)}
              Personal Details
            </span>
            <span className="flex flex-col justify-center items-center text-sm capitalize gap-1 font-semibold cursor-pointer">
              {checkSteps(3)}
              inquiry Status
            </span>
          </div>
          {conatiner(step)}
        </div>
      </div>
    );
  } else {
    return (
      <div className="min-h-screen min-w-screen flex justify-center items-center ">
        <Oval
          visible={true}
          height="64"
          width="64"
          color="var(--text)"
          secondaryColor="var(--primary-color2)"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperclassName=""
        />
      </div>
    );
  }
}

export default BookingSection;
