import React from 'react'

function InstagramEmbed() {
  return (
    <section className="text-gray-600   body-font  ">
    <div className="px-5 md:py-24 pb-24 mx-auto my-auto flex md:flex-row flex-col md:gap-20 ">
      <h5 className="  title-font text-gray-900 mb-12 text-center  tracking-tight md:text-6xl capitalize  text-clip opacity-80  md:w-1/2  w-full flex items-center">
        <div className="md:w-3/4 w-full md:text-8xl text-5xl font-medium  md:text-end ml-auto text-center my-auto">
          See What We've Been Doing
        </div>
      </h5>
      <div className=" md:w-1/2 w-full flex md:justify-start justify-center items-center">
        <iframe
          title="instagram"
          src="https://www.instagram.com/aarohan_holidays/embed/"
          className="md:w-[75%] md:h-[570px] w-[95%] h-[440px]  border-2 p-1 rounded-md bg-gray-50 "
          height="auto"
          scrolling="no"
          allowtransparency="true"
        ></iframe>
      </div>
    </div>
  </section>
  )
}

export default InstagramEmbed