import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MyContext } from "./MyContext";
const Links = {
  "Home": "/",
  "gallery": "/gallery",
  "Pakages": "/pakages",
  "Plan Yourself": "/plan",
  "Contact": "/contact",
  "About Us": "/about",
  "sitemap": "/sitemap",
  "Terms & Conditions": "/terms_Conditions",
  "Privacy Policy": "/privacy_policy",
  "Work With Us": "/carrer",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MyContext.Provider value={{ Links }}>
      <App />
    </MyContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
