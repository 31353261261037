import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
function Weather({ location }) {
  const parse = require("html-react-parser").default;

  const options = { method: "GET", headers: { accept: "application/json" } };

  const { data, isLoading, status } = useQuery({
    queryKey: ["Weather_" + location.city.name],
    queryFn: async () => {
      const res = await fetch(
        // `https://api.tomorrow.io/v4/weather/realtime?location=${location.city.name}&units=metric&apikey=${process.env.WEATHER_API}`,
        // `https://api.tomorrow.io/v4/weather/realtime?location=${location.city.name}&units=metric&apikey=PznebM4JuER5Succ8NORGAJOc30NidRQ`,
        `http://api.weatherapi.com/v1/current.json?q=${location.city.name}&key=698e9170a92d4eb4843102440241805`,
        options
      );
      const data = await res.json();

      return data;
    },
    cacheTime: 300000,
    staleTime: 300000,
    refetchInterval: 300000,
    refetchIntervalInBackground: true,
  });

  //   setWeather(data);
  //   function ApiCall(location) {
  //   }
  //   useEffect(() => {
  //     const location = props.location || null;
  //     if (location) {
  //       ApiCall(location);
  //     }
  //   }, [weather]);
  const icons = {
    clearSky: {
      icon: `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        className="bi bi-moon-stars fill-black"
        viewBox="0 0 16 16"
      >
        <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278M4.858 1.311A7.27 7.27 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.32 7.32 0 0 0 5.205-2.162q-.506.063-1.029.063c-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286" />
        <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
      </svg>`,
    },
    cloudMoon: {
      icon: `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      className="bi bi-cloud-moon fill-black"
      viewBox="0 0 16 16"
    >
      <path d="M7 8a3.5 3.5 0 0 1 3.5 3.555.5.5 0 0 0 .625.492A1.503 1.503 0 0 1 13 13.5a1.5 1.5 0 0 1-1.5 1.5H3a2 2 0 1 1 .1-3.998.5.5 0 0 0 .509-.375A3.5 3.5 0 0 1 7 8m4.473 3a4.5 4.5 0 0 0-8.72-.99A3 3 0 0 0 3 16h8.5a2.5 2.5 0 0 0 0-5z" />
      <path d="M11.286 1.778a.5.5 0 0 0-.565-.755 4.595 4.595 0 0 0-3.18 5.003 5.5 5.5 0 0 1 1.055.209A3.6 3.6 0 0 1 9.83 2.617a4.593 4.593 0 0 0 4.31 5.744 3.58 3.58 0 0 1-2.241.634q.244.477.394 1a4.59 4.59 0 0 0 3.624-2.04.5.5 0 0 0-.565-.755 3.593 3.593 0 0 1-4.065-5.422z" />
    </svg>`,
    },
    clouds: {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  className="bi bi-clouds fill-black" viewBox="0 0 16 16">
    <path d="M16 7.5a2.5 2.5 0 0 1-1.456 2.272 3.5 3.5 0 0 0-.65-.824 1.5 1.5 0 0 0-.789-2.896.5.5 0 0 1-.627-.421 3 3 0 0 0-5.22-1.625 5.6 5.6 0 0 0-1.276.088 4.002 4.002 0 0 1 7.392.91A2.5 2.5 0 0 1 16 7.5"/>
    <path d="M7 5a4.5 4.5 0 0 1 4.473 4h.027a2.5 2.5 0 0 1 0 5H3a3 3 0 0 1-.247-5.99A4.5 4.5 0 0 1 7 5m3.5 4.5a3.5 3.5 0 0 0-6.89-.873.5.5 0 0 1-.51.375A2 2 0 1 0 3 13h8.5a1.5 1.5 0 1 0-.376-2.953.5.5 0 0 1-.624-.492z"/>
    </svg>`,
    },
    rain: {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  className="bi bi-cloud-drizzle fill-black" viewBox="0 0 16 16">
    <path d="M4.158 12.025a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317m6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317m-3.5 1.5a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317m6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 1 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317m.747-8.498a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 11H13a3 3 0 0 0 .405-5.973M8.5 2a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4 4 0 0 1 8.5 2"/>
    </svg>`,
    },
    thunder: {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  className="bi bi-cloud-lightning fill-black" viewBox="0 0 16 16">
      <path d="M13.405 4.027a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 10H13a3 3 0 0 0 .405-5.973M8.5 1a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4 4 0 0 1 8.5 1M7.053 11.276A.5.5 0 0 1 7.5 11h1a.5.5 0 0 1 .474.658l-.28.842H9.5a.5.5 0 0 1 .39.812l-2 2.5a.5.5 0 0 1-.875-.433L7.36 14H6.5a.5.5 0 0 1-.447-.724z"/>
    </svg>`,
    },
    snow: {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  className="bi bi-cloud-snow fill-black" viewBox="0 0 16 16">
      <path d="M13.405 4.277a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 10.25H13a3 3 0 0 0 .405-5.973M8.5 1.25a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1-.001 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4 4 0 0 1 8.5 1.25M2.625 11.5a.25.25 0 0 1 .25.25v.57l.501-.287a.25.25 0 0 1 .248.434l-.495.283.495.283a.25.25 0 0 1-.248.434l-.501-.286v.569a.25.25 0 1 1-.5 0v-.57l-.501.287a.25.25 0 0 1-.248-.434l.495-.283-.495-.283a.25.25 0 0 1 .248-.434l.501.286v-.569a.25.25 0 0 1 .25-.25m2.75 2a.25.25 0 0 1 .25.25v.57l.501-.287a.25.25 0 0 1 .248.434l-.495.283.495.283a.25.25 0 0 1-.248.434l-.501-.286v.569a.25.25 0 1 1-.5 0v-.57l-.501.287a.25.25 0 0 1-.248-.434l.495-.283-.495-.283a.25.25 0 0 1 .248-.434l.501.286v-.569a.25.25 0 0 1 .25-.25m5.5 0a.25.25 0 0 1 .25.25v.57l.501-.287a.25.25 0 0 1 .248.434l-.495.283.495.283a.25.25 0 0 1-.248.434l-.501-.286v.569a.25.25 0 1 1-.5 0v-.57l-.501.287a.25.25 0 0 1-.248-.434l.495-.283-.495-.283a.25.25 0 0 1 .248-.434l.501.286v-.569a.25.25 0 0 1 .25-.25m-2.75-2a.25.25 0 0 1 .25.25v.57l.501-.287a.25.25 0 0 1 .248.434l-.495.283.495.283a.25.25 0 0 1-.248.434l-.501-.286v.569a.25.25 0 1 1-.5 0v-.57l-.501.287a.25.25 0 0 1-.248-.434l.495-.283-.495-.283a.25.25 0 0 1 .248-.434l.501.286v-.569a.25.25 0 0 1 .25-.25m5.5 0a.25.25 0 0 1 .25.25v.57l.501-.287a.25.25 0 0 1 .248.434l-.495.283.495.283a.25.25 0 0 1-.248.434l-.501-.286v.569a.25.25 0 1 1-.5 0v-.57l-.501.287a.25.25 0 0 1-.248-.434l.495-.283-.495-.283a.25.25 0 0 1 .248-.434l.501.286v-.569a.25.25 0 0 1 .25-.25"/>
    </svg>`,
    },
  };
  return (
    <div className="duration-200 ease-in-out ">
      {isLoading ? (
        <>
          <div className="flex flex-row items-center justify-between w-max gap-4">
            <span className="flex flex-row gap-2 items-center ">
              <span className="flex flex-row justify-center items-center gap-2 text-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  className="bi bi-thermometer-half fill-black"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415" />
                  <path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1" />
                </svg>
                <span>
                  30 <sup>o</sup>C
                </span>
              </span>
            </span>
            <span className="flex flex-row gap-2 items-center ">
              <span className="flex flex-row justify-center items-center gap-2 text-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  class="bi bi-eyeglasses"
                  viewBox="0 0 16 16"
                  className="fill-black"
                >
                  <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A2 2 0 0 0 8 6c-.532 0-1.016.208-1.375.547M14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
                </svg>
                8 Km
              </span>
            </span>
          </div>
        </>
      ) : status === "success" && data.current.condition ? (
        <>
          <div className="flex flex-row items-center justify-between w-max gap-4">
            <span className="flex flex-row gap-2 items-center " title="Temperature">
              <span className="flex flex-row justify-center items-center gap-2 text-lg">
                {data.current.condition.text.toLowerCase().includes("rain") ||
                data.current.condition.text
                  .toLowerCase()
                  .includes("showers") ? (
                  parse(String(icons.rain.icon))
                ) : data.current.condition.text
                    .toLowerCase()
                    .includes("clear") ||
                  data.current.condition.text
                    .toLowerCase()
                    .includes("sunny") ? (
                  parse(String(icons.clearSky.icon))
                ) : data.current.condition.text
                    .toLowerCase()
                    .includes("cloudy") ||
                  data.current.condition.text.toLowerCase().includes("mist") ||
                  data.current.condition.text
                    .toLowerCase()
                    .includes("overcast") ? (
                  parse(String(icons.cloudMoon.icon))
                ) : data.current.condition.text
                    .toLowerCase()
                    .includes("snow") ||
                  data.current.condition.text.toLowerCase().includes("ice") ? (
                  parse(String(icons.snow.icon))
                ) : data.current.condition.text
                    .toLowerCase()
                    .includes("thunder") ? (
                  parse(String(icons.thunder.icon))
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      className="bi bi-thermometer-half fill-black"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415" />
                      <path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1" />
                    </svg>
                  </>
                )}
                <span>
                  {data.current.temp_c} <sup>o</sup> C
                </span>
              </span>
            </span>
            <span
              className="flex flex-row gap-2 items-center "
              title="visibility"
            >
              <span className="flex flex-row justify-center items-center gap-2 text-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  class="bi bi-eyeglasses"
                  viewBox="0 0 16 16"
                  className="fill-black"
                >
                  <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A2 2 0 0 0 8 6c-.532 0-1.016.208-1.375.547M14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
                </svg>
                {data.current.vis_km} Km
              </span>
            </span>
          </div>
        </>
      ) : status === "error" ? (
        <>No Weather Details Found</>
      ) : (
        <></>
      )}
      {status === "success" && data.code ? <>error</> : <></>}
    </div>
  );
}

export default Weather;
