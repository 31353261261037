import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import PakageCard from "./PakageCard";
import { Oval } from "react-loader-spinner";
import NotFound from "../assects/No data.gif";
function PakagesConatiner({ filter,handelClearFilter }) {
  const filtered = filter;
  const [requestQueryKey, setRequestQueryKey] = useState("pakages_");
  const [requestQuery, setRequestQuery] = useState("filter?");
  const [RequestStatus, setRequestStatus] = useState(200);
  useEffect(() => {
    let newRequestQueryKey = "pakages_";
    let newRequestQuery = "filter?";
    // Build the query string based on the filter object
    Object.entries(filtered).forEach(([key, value]) => {
      newRequestQueryKey += `${key}_${value}_`;
      newRequestQuery += `${key}=${value.toString().toLowerCase()}&`;
    });

    setRequestQueryKey(newRequestQueryKey);
    setRequestQuery(newRequestQuery);
  }, [filtered]);
  console.log(requestQuery);
  // Assuming props.filter is an object
  const { isSuccess, isLoading, data } = useQuery({
    queryKey: [`pakages_${requestQueryKey}`],
    queryFn: async () => {
      const res = await fetch(process.env.REACT_APP_BASE_URL + requestQuery);
      const data = await res.json();
      setRequestStatus(res.status);
      return data;
    },
    enabled: !!filtered,
  });
  return (
    <>
      {!isLoading ? (
        isSuccess && RequestStatus === 200 && Array.isArray(data)? (
          <>
            
            {data?.map((e, index) => {
              return (
                <PakageCard
                  key={index}
                  image={e.tumbnail[0].photo}
                  city={e.main_location.city.name}
                  state={e.main_location.city.region.name}
                  duration={`${e.duration.day} days ${e.duration.night} Nights`}
                  heading={e.heading}
                  desc={e.packageInfo}
                  price={e.price}
                  slug={e.slug}
                  id={e.id}
                  loop={true}
                  pakageData={e}
                />
              );
            })}
          </>
        ) : (
          <div className="flex flex-col justify-center items-center text-center h-3/5  ">
            <img src={NotFound} alt="" />
            
            <p className="md:text-2xl text-md font-semibold">No packages found for the given criteria</p>
            <button className="border-2 border-gray-600 hover:bg-gray-100 hover:text-blue-900 hover:border-blue-900 bg-transparent font-bold px-4 p-2 rounded-md mt-4" 
             onClick={handelClearFilter}>Reset Filters</button>
          </div>
          // "Error while loading"
        )
      ) : (
        <>
          <Oval
            visible={true}
            height="64"
            width="64"
            color="var(--text)"
            secondaryColor="var(--primary-color2)"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </>
      )}
    </>
  );
}

// Define default props for the PakagesConatiner component
PakagesConatiner.defaultProps = {
  filter: {
    categorie: "",
    sortby: "manual",
    destination: "",
  },
};
export default PakagesConatiner;
