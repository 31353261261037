import React from "react";
import HeadText from "../components/HeadText";
import { Link } from "react-router-dom";
function Carrer() {
  return (
    <div className="text-justify  w-4/5 py-32 mx-auto">
      <HeadText title="Career" subtitle="TRAVEL AND WORK TOGETHER" />
      <div className="text-center ">
        <h3 className="text-2xl font-semibold pb-3">
          Team members we're looking for
        </h3>

        <p className="tracking-wide">
          Just like us, you believe in our vision that
          <b>"everyone must travel"</b>. You want to define the future of
          trekking in our country. There are three ways in which you may work
          with Aarohan Holidays.
          <ol className="text-start list-disc sm:w-max mx-auto my-5 md:w-[50vw]">
            <li>Work at our office</li>
            <li>Work in the outdoors</li>
            <li>Work from home</li>
          </ol>
          While trekking is a passion, the main reason to work with us is
          because you believe in our vision -- that
          <b>"everyone must travel"</b>. You believe in it strongly and from the
          core of your heart. You want to remove obstacles; solve problems that
          are there in the trekking world.
          <div className="my-3">
            With this in mind, take a look at the open positions and choose one
            that may suit you best. Do not apply multiple options
          </div>
          <ol className="text-start list-disc sm:w-max mx-auto my-5 md:w-[50vw]">
            <li>Graphic Designer</li>
            <li>Team Captain(Outdoor expert)</li>
            <li>Content creator</li>
            <li>Social media manager</li>
          </ol>
          <p>You can directly <Link to="/contact" className="text-blue-800 font-semibold">contact us</Link> with a proper subject line of applying position</p>
        </p>
      </div>
    </div>
  );
}

export default Carrer;
