import React from "react";
import HeadText from "../components/HeadText";
import { SITEDATA } from "../localData";

function TermsAndConditions() {
  function TermSection({ title, body }) {
    return (
      <div className="text-justify py-7">
        <h3 className="text-center text-lg text-balance font-semibold py-3">
          {title}
        </h3>
        <p
          className="mb-5 text-center "
          dangerouslySetInnerHTML={{ __html: body }}
        ></p>
      </div>
    );
  }

  return (
    <div className="terms-and-conditions text-justify sm:w-1/2 w-3/4 py-32 mx-auto">
      <HeadText
        title="Terms And Conditions"
        subtitle="last updated - 28 may 2024"
      />
      <div className="">
        <TermSection
          title="Introduction"
          body="These terms and conditions govern your use of our website and
            services. By using them, you agree to be bound by this agreement. If you disagree, please refrain from using our website or services."
        />

        <TermSection
          title="Services"
          body="We offer trekking and touring services in various locations in
            Maharashtra. Our services may include transportation, accommodation,
            meals, and guided tours. Specific details will be outlined in the tour
            description or communicated to you upon booking."
        />

        <TermSection
          title="Bookings and Payments"
          body="To book, provide your name, contact information, and payment details.
            By booking, you confirm your legal right to enter this agreement and
            use the payment method provided. 
            <br/><br/>
            We accept credit cards, debit cards,
            or bank transfers, processed at booking time. You'll receive a
            confirmation email with reservation details."
        />
        <TermSection
          title="Cancellation and Refunds"
          body="Our cancellation and refund policy is outlined in a separate document, which can be found on our website. By booking a tour with us, you agree to be bound by our cancellation and refund policy. "
        />
        <TermSection
          title="Health and Safety"
          body="Participation in our tours may involve physical activity and exposure to outdoor elements. You are responsible for ensuring that you are in good health and capable of participating in the tour activities. You should also follow any safety instructions provided by our guides or staff.

          <br/><br/>

          We reserve the right to refuse service to anyone who we believe may pose a risk to themselves or others."
        />
        <TermSection
          title="Limitation of Liability"
          body="We are not liable for any loss, injury, or damage to person or property arising from your use of our website or services, unless such loss, injury, or damage is caused by our gross negligence or willful misconduct.


          <br/><br/>
          In no event shall our liability exceed the amount paid by you for the tour.
          
          "
        />
        <TermSection
          title="Intellectual Property
          "
          body="
          Our website and all content and materials contained therein, including trademarks, logos, and images, are the property of Trek Diaries and are protected by copyright and other intellectual property laws.
          
          <br/><br/>
          
          You may not use our website or content for any commercial or unlawful purpose without our express written consent.
                    
          "
        />
        <TermSection
          title="Termination"
          body="We may terminate this Agreement at any time if you breach any of its terms. Upon termination, you must immediately cease using our website and services.          "
        />
        <TermSection
          title="Changes to this Agreement
          "
          body="
          We may update this Agreement from time to time. The updated agreement will be posted on our website with the updated effective date. Your continued use of our website or services after the effective date constitutes your acceptance of the updated agreement.
          
          "
        />
        <TermSection
          title="Governing Law"
          body="This Agreement shall be governed by and construed in accordance with the laws of the state of Maharashtra, India."
        />
        <TermSection
          title="Contact Us"
          body={`If you have any questions or concerns about this Agreement, please contact us at  <a class="text-blue-900 opacity-90" href="mailto:${SITEDATA.Email}">${SITEDATA.Email}</a> <br/> <br/>
          By using our website and services, you agree to be bound by the terms and conditions outlined in this Agreement.`}
        />

        {/* ... (Rest of your TermSection components) */}
      </div>
    </div>
  );
}

export default TermsAndConditions;
