import React from "react";
import { Link } from "react-router-dom";
import "html-react-parser";

function ImageCard(props) {
  
  const parseHtml = require("html-react-parser").default;
  if (props.loading) {
    return (
      <div className="relative block group ">
        <div className="h-[450px] w-[300px] object-cover rounded-3xl bg-gray-300"></div>

        <div className="absolute inset-0 flex flex-col items-start justify-end p-6 backdrop-brightness-50  rounded-3xl transition duration-500">
          <span className="text-2xl font-medium text-[var(--primary-color)] capitalize text-center lg:text-start h-8 bg-gray-400 w-[60%] rounded-md animate-pulse duration-1000 transition-all"></span>
          <div className="border border-[var(--primary-color)] lg:w-1/4 border-b-2 mt-2 mb-2 w-1/2 mx-auto lg:ml-0 "></div>
          <p className="mt-1.5 max-w-[40ch] text-xs  text-justify lg:text-start h-[25%] w-full bg-gray-200 rounded-md animate-pulse duration-1000 transition-all"></p>
        </div>
      </div>
    );
  } else {
    return (
      <Link
        to={`/pakages?destination=${props.link}`}
        className="relative block group "
      >
        <img
          src={props.image}
          alt=""
          className="h-[450px] w-[300px] object-cover rounded-3xl"
        />

        <div className="absolute inset-0 flex flex-col items-start justify-end p-6 backdrop-brightness-50 group-hover:backdrop-brightness-75 rounded-3xl transition duration-500">
          <h3 className="text-2xl font-medium text-[var(--primary-color)] capitalize text-center lg:text-start w-full">
            {props.name}
          </h3>
          <div className="border border-[var(--primary-color)] lg:w-1/4 border-b-2 mt-2 mb-2 w-1/2 mx-auto lg:ml-0 "></div>
          <p className="mt-1.5 max-w-[40ch] text-xs text-white text-justify lg:text-start h-[25%]">
            {parseHtml(truncate(props.desc))}{" "}
          </p>
        </div>
      </Link>
    );
  }
}

ImageCard.defaultProps = {
  loading: false,
};

export default ImageCard;

function truncate(str) {
  return str.length > 250 ? str.substring(0, 250) + "..." : str;
}
