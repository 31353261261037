import React, { useState, useEffect, useMemo } from "react";

const TourForm = () => {
  const [minDate, setMinDate] = useState("");

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    tour_type: 'educational',
    group_size: 1,
    duration: '1',
    start_date: "",
    destination: "",
    tour_explain: "",
    tansport_type: "non_ac",
  });

  const TourType = useMemo(
    () => ({
      educational:
        "Educational Tours (School / College Tours / Industrial Visits)",
      private: "Pilgrimage Tours (Group / Private Tours)",
      corporate: "Corporate Tours / Events",
      international: "Domestic Holiday / International Holiday Tour Packages",
      honeymoon: "Honeymoon",
    }),
    []
  );
  const duration = useMemo(
    () => ({
      1: "1 day",
      2: "2 days",
      3: "3 days",
      4: "4 days",
      5: "5 days",
      6: "6 days",
      7: "7 days",
      8: "8 days",
      9: "9 days",
      10: "10 days",
      11: "11 days",
      12: "12 days",
      13: "13 days",
      14: "14 days",
      15: "15 days",
    }),
    []
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (formData.phone.lenght <= 10) {
        setFormData({ ...formData, phone: value });
      } else {
        setFormData({ ...formData, phone: value.slice(0, 10) });
      }
    } else if (name === "group_size") {
      if (formData.group_size.lenght <= 2) {
        setFormData({ ...formData, group_size: value });
      } else {
        setFormData({ ...formData, group_size: value.slice(0, 2) });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  let [responseData, setResponseData] = useState("works");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // You can handle form submission here, for example, sending the data to an API
    fetch(process.env.REACT_APP_BASE_URL + "create_user_plan", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((response) => {
      let data = response.json();
      if (response.status === 201) {
        setDisableUsablity(true);
      }
      setResponseData(response);
      return data;
    });
    console.log(formData);
  };
  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    setMinDate(formatDate(tomorrow));
    setFormData({
      ...formData
    });
  }, []);
  const [DisableUsablity, setDisableUsablity] = useState(false);
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={
          "max-w-[90%] mx-auto mt-8 bg-white p-6 rounded-lg shadow-lg" +
          (DisableUsablity ? "pointer-events-none opacity-80" : "")
        }
      >
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-name"
            >
              Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-name"
              type="text"
              placeholder="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              disabled={DisableUsablity}
              
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-email"
            >
              Email
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="jhonn@gmail.com"
              disabled={DisableUsablity}
              inputMode="email"
            />
          </div>
        </div>
        {/* trek name */}
        <div className="flex flex-wrap -mx-3 mb-6 ">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-destination"
            >
              Destination
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-destination"
              type="text"
              placeholder="Ex. Raigad fort"
              name="destination"
              value={formData.destination}
              onChange={handleChange}
              disabled={DisableUsablity}
            />
          </div>
        </div>
        {/* second row  phone , tour_type , group size */}
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-Phone"
            >
              Phone
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-Phone"
              type="number"
              name="phone"
              placeholder="9021000888"
              value={formData.phone}
              onChange={handleChange}
              disabled={DisableUsablity}
              required
              inputMode="numeric"
            />
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-tour_type"
            >
              Tour Type
            </label>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-tour_type"
                value={formData.tour_type}
                onChange={handleChange}
                name="tour_type"
                disabled={DisableUsablity}
                required
              >
                {Object.entries(TourType).map(([key, value]) => {
                  
                  return (
                    <option key={key} className="capitalize" value={key}>
                      {value}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-group_size"
            >
              Group Size
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-group_size"
              type="number"
              name="group_size"
              value={formData.group_size}
              onChange={handleChange}
              placeholder="Size of group"
              disabled={DisableUsablity}
              inputMode="decimal"
            />
          </div>
        </div>
        {/* third row  */}
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-duration"
            >
              Duration <small>(in days)</small>
            </label>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                required
                disabled={DisableUsablity}
              >
                {Object.entries(duration).map(([key, value]) => {
                  return (
                    <option key={key} className="" value={key}>
                      {value}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-start_date"
            >
              Start Date
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 uppercase"
              id="grid-start_date"
              type="date"
              name="start_date"
              value={formData.start_date}
              onChange={handleChange}
              min={minDate}
              required
              disabled={DisableUsablity}
            />
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-tansport_type"
            >
              Transport Type
            </label>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-tansport_type"
                name="tansport_type"
                value={formData.tansport_type}
                onChange={handleChange}
                required
                disabled={DisableUsablity}
              >
                <option className="capitalize" value="non_ac">
                  NON AC
                </option>
                <option className="capitalize" value="ac">
                  AC
                </option>
                z
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6 ">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-tour_explain"
            >
              Tour Explain
            </label>
            <textarea
              className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-[20vh] text-noraml opacity-90"
              id="grid-tour_explain"
              type="text"
              placeholder="Explain your expected results"
              name="tour_explain"
              value={formData.tour_explain}
              onChange={handleChange}
              disabled={DisableUsablity}
            />
            <p className="text-gray-600 text-xs italic">
              Make it as long and as crazy as you'd like
            </p>
          </div>
        </div>

        <div className="mb-4 text-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded "
            disabled={DisableUsablity}
          >
            Submit
          </button>
        </div>
      </form>
      <div className="flex justify-center items-center text-center text-lg mt-5 capitalize text-opacity-90 font-semibold">
        {responseData ? (
          responseData.status === 201 ? (
            <>
              <span className="text-green-400">
                Your customized tour Plan has been collected by us we will contact
                you soon!!
              </span>
            </>
          ) : (
            <>
              <span className="text-red-500">{responseData.statusText}</span>
            </>
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default TourForm;
