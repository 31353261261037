import React from 'react'

function HeadText(props) {
  return (
    <div className=" flex flex-col justify-center ">
        <div className="container mx-auto my-auto text-center mb-12">
          <h1 className={"text-4xl font-bold title-font text-gray-900 mb-2 text-center  tracking-tight md:text-6xl capitalize  text-clip opacity-80 "  + props.mainClass}>
          {props.title}
            <br />
          </h1>
          <small className={"font-semibold text-gray-500 capitalize " + props.subClass}>{props.subtitle}</small>
        </div>
      </div>
  )
}

export default HeadText